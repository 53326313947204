<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empJobTitleRightArrow_{{row}}"
    [disabled]="(showTitle && !empSubForm.controls.jobTitle.valid) || (showDriverDuties && !empSubForm.controls.driverDuties.valid)  || (showReasonForLeaving && !empSubForm.controls.reasonForLeaving.valid) || (showFaaSafetyCheck && !empSubForm.controls.faaSafetyCheck.valid)"
    debounceDirective (debounceClick)="jobTitle(empSubForm.value)"> <i class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empJobTitleLeftArrow_{{row}}" debounceDirective (debounceClick)="getPrevSwipe()"><i
      class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>

  <div class="anime-clean contetnheight posrel">
    <div class="jobTitleMiddleContainer force-osx-scrollbar">
        <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12">
              <div class="header_txt_white"
                *ngIf="showTitle && (empType === 'current' || (empType === 'selfEmployed' && isPaDOTDirectEnabled && row === 0 ) || (isCheckSelfEmploymentEnabled && row === 0))">
                <h2>{{'EMPLOYMENT_TITLE_HEADER_1'|translate }}</h2>
                <h4>{{'EMPLOYMENT_TITLE_SUBHEADER_1'|translate }}</h4>
      
                <h4 class="m-t-15"><i class="icon-work-experience"></i> <span
                    class="company">{{empSubForm.controls['companyName'].value}}</span></h4>
              </div>
              <div class="header_txt_white"
                *ngIf="showTitle && (empType === 'prior' || (empType === 'selfEmployed' && isPaDOTDirectEnabled && row > 0 ) || (isCheckSelfEmploymentEnabled && row > 0))">
                <h2>{{'EMPLOYMENT_TITLE_HEADER_2'|translate }}</h2>
                <h4>{{'EMPLOYMENT_TITLE_SUBHEADER_1'|translate }}</h4>
                <h4 class="m-t-15"><i class="icon-work-experience"></i> <span
                    class="company">{{empSubForm.controls['companyName'].value}}</span></h4>
              </div>
              <div class="header_txt_white" *ngIf="!showTitle && !!empSubForm.controls['companyName']">
                <h2>{{'EMPLOYMENT_INTLADDITIONAL_HEADER_1'|translate : [userDetails.firstname]}}</h2>
                <h4 class="m-t-15 m-b-20"><i class="icon-work-experience"></i> <span
                    class="company">{{empSubForm.controls['companyName'].value}}</span>
                </h4>
              </div>
            </div>
          </div>
          <div *ngIf="showTitle" class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
              <div class="group form-element-section">
                <label class="control-labelss" attr.for="empjobTitle_{{row}}">{{'EMPLOYMENT_TITLE_LABLE'|translate }}</label>
                <input type="text" class="form-control" attr.id="empjobTitle_{{row}}" aria-required="true" formControlName="jobTitle" maxlength="50" />
              </div>
            </div>
          </div>
          <div *ngIf="showDriverDuties" class="row">
            <div class="col-sm-12 col-xs-12 col-md-12">
              <label class="radioLabel displayBlock" for="dot" tabindex="0">
                <span>{{'EMPLOYMENT_DRIVING_DUTIES_LABLE'|translate }}</span><br />
              </label>
              <div class="col-xs-6 col-sm-2 col-md-2 m-b-20">
                <div class="group">
                  <label class="radio-inline radioLabelContent" for="empjobTitleRadioYes_{{row}}">{{'LABEL_YES' | translate}} <input type="radio" class=""
                    formControlName="driverDuties" value='true' id="empjobTitleRadioYes_{{row}}" aria-required="true" [checked]="empSubForm.controls.driverDuties.value===true"></label>
                </div>
              </div>
              <div class="col-xs-6 col-sm-2 col-md-2 m-b-20">
                <div class="group">
                  <label class="radio-inline radioLabelContent" for="empjobTitleRadioNo_{{row}}">{{'LABEL_NO' | translate}} <input type="radio" class=""
                    formControlName="driverDuties" value='false' id="empjobTitleRadioNo_{{row}}" aria-required="true" [checked]="empSubForm.controls.driverDuties.value===false"></label>
                </div>
              </div>
            </div>
      
          </div>
          <div *ngIf="showFaaSafetyCheck" class="row">
            <div class="col-sm-12 col-xs-12 col-md-12">
              <label class="radioLabel displayBlock" for="dot" tabindex="0">
                <span>{{'EMPLOYMENT_FAA_SAFETY_CHECK_LABLE'|translate }}</span><br />
              </label>
              <div class="col-xs-6 col-sm-2 col-md-2 m-b-20">
                <div class="group">
                  <label class="radio-inline radioLabelContent" for="empSafetyCheckRadioYes_{{row}}">{{'LABEL_YES' | translate}} <input type="radio" class=""
                    formControlName="faaSafetyCheck" value='true' id="empSafetyCheckRadioYes_{{row}}" aria-required="true" [checked]="empSubForm.controls.faaSafetyCheck.value===true"></label>
                </div>
              </div>
              <div class="col-xs-6 col-sm-2 col-md-2 m-b-20">
                <div class="group">
                  <label class="radio-inline radioLabelContent" for="empSafetyCheckRadioYes_{{row}}">{{'LABEL_NO' | translate}} <input type="radio" class=""
                    formControlName="faaSafetyCheck" value='false' id="empSafetyCheckRadioYes_{{row}}" aria-required="true" [checked]="empSubForm.controls.faaSafetyCheck.value===false"></label>
                </div>
              </div>
            </div>
      
          </div>          
          <div *ngIf="showReasonForLeaving" class="row">
            <div class="col-sm-12 col-xs-12 col-md-12">
              <div class="group">
                <label class="control-labelss" for="dotReasonForLeave">{{'EMPLOYMENT_REASON_FOR_LEAVING'|translate }}</label>
                <textarea rows="2" class="form-control" id="dotReasonForLeave" aria-required="true" formControlName="reasonForLeaving"
                  maxlength="255"></textarea>
              </div>
            </div>
          </div>
    </div>
    
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button font_extrabold" attr.data-id="empJobTitleNext_{{row}}"
          [class.grow]="showTitle && empSubForm.controls.jobTitle.valid"
          [disabled]="(showTitle && !empSubForm.controls.jobTitle.valid) || (showDriverDuties && !empSubForm.controls.driverDuties.valid) || (showReasonForLeaving && !empSubForm.controls.reasonForLeaving.valid) || (showFaaSafetyCheck && !empSubForm.controls.faaSafetyCheck.valid)"
          debounceDirective (debounceClick)="jobTitle(empSubForm.value)">{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <!--<div class="col-xs-12 col-sm-4 col-md-3 marginbot20 mar30 p_l_0 m-t-100 p_r_0 m-r-15  m-b-15">
                          <button type="button" class="btn button grow font_extrabold ssnfrmbtn">INFORMATION UNAVAILABLE</button>
                      </div>-->
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg font_extrabold sendButton grow button_wbgSav"
          attr.data-id="empJobTitleSaveExit_{{row}}" debounceDirective (debounceClick)="saveExit(empSubForm.value)"
          [disabled]="(showTitle && !empSubForm.controls.jobTitle.valid) || (showDriverDuties && !empSubForm.controls.driverDuties.valid) || (showReasonForLeaving && !empSubForm.controls.reasonForLeaving.valid) || (showFaaSafetyCheck && !empSubForm.controls.faaSafetyCheck.valid)">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
