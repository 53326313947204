<div class="container">
  <div class="col-md-12">
    <div class="footerComp">
      <div class="privacyPolicyFooter">
        <!--
                  Scenario 1: Footer links in Login Page - Default English
               -->
        <a *ngIf="!isLanguageReceived && !isEARightId && isShowTermsServices && !isShowTOS" href="#" (click)="openTermsModal($event)">{{ termsOfServiceTitle }}</a>
        <a *ngIf="!isLanguageReceived && isShowTermsServices" href="https://fadv.com/privacy-policy.aspx"
          target="_blank" rel="noopener">{{ policy }}</a>
        <!--
                  Scenario 2: Footer links in Login Page to reflect the INVITE based language value
               -->
        <a *ngIf="isLanguageReceived && !isEARightId && isShowTermsServices && !isShowTOS" href="#" (click)="openTermsModal($event)">{{ termsOfServiceTitle }}</a>
        <a *ngIf="isLanguageReceived && isShowTermsServices" href="https://fadv.com/privacy-policy.aspx"
          target="_blank" rel="noopener">{{ policy }}</a>
        <a *ngIf="isContactUsAvailable" (click)="openContactUsModal()">{{ contactUs }}</a>
      </div>
    </div>
  </div>
</div>

<div id="contactUsModal" class="modal fade custom_modal" role="dialog">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" [innerHTML]="instruction"></h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<div id="termsServices" class="modal fade custom_modal" role="dialog">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <i class="icon-cancel"></i>
        </button>
        <h3 class="modal-title">{{termsOfServiceHeader}}</h3>
      </div>
      <div class="modal-body" id="termsServicesModal" [innerHTML]="termsOfServicesData">
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
</div>
