import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from 'framework/localstorage.service';
import { EventBusService } from 'framework/eventbus.service';
import { SharedService } from 'app/shared/services/shared.service';

declare var jQuery: any;
declare var window: any;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private localStorage: LocalStorageService,
              private sharedService: SharedService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authHeader = request.headers.get('Authorization');
    let authReq = request;
    
    if (!!authHeader && authHeader !== 'Bearer undefined') {
      let newToken = 'Bearer ' + this.localStorage.getItem('access_token');
      
      if (newToken !== authHeader) {
        window.track_event("token mismatch, request with new token", { stage_name: 'intercept', action_value: 'request' }, true, true, true);
        
        authReq = request.clone({
          headers: request.headers.set('Authorization', newToken)
        });
      }
    }

    return next.handle(authReq).pipe(
      map(res => { return res; }),
      catchError(err => this._handleError(err))
    );
  }

  private isMFAError(error: {}) {
    if (!!error && !!error['error']) {
      error = error['error'];
    }
    return !!(!!error && !!error['additionalProperties'] && !!error['additionalProperties']['additional_error_detail'] && !!error['additionalProperties']['additional_error_detail']['action'] && error['additionalProperties']['additional_error_detail']['action']==='INVALID_PASSWORD_UP');
  }

  private _handleError(error: any) {
    if (!!error && error.status === 401) {
      let errorMessge = '';
      let _body = error || {};
      if (!!_body['error']) {
        errorMessge = _body['error']['error'];
      }
      
      window.track_event("http request with new token failed", { stage_name: 'intercept', action_value: 'error' }, true, true);
      this.sharedService.logFlow('auth interceptor', "http request with new token failed", "show auth error modal, " + this.sharedService.getDeviceDetails());
      const isMFAError = this.isMFAError(_body);
      if (!!isMFAError || (!!errorMessge && (errorMessge.startsWith("INVALID_LOGIN#") || errorMessge.startsWith("Invalid Username") || errorMessge.startsWith("SECRET_QUESTION_ANSWER_MISMATCH#")))) {
        //Invalid login or MFA Error scenario to be handled by OIDC Error handler
      } else {
        if (!!errorMessge && (errorMessge.startsWith("Invalid Authorization"))) {
          EventBusService.sessionInvalidReceivedAnnounced({
            isSessionValid: true
          });
        }
        let isAuthModelOpened = jQuery('#auth-error-modal').hasClass('in');
        if (!isAuthModelOpened) {
          // closes all active pop ups.
          jQuery('.modal').modal('hide');
          jQuery('.modal-backdrop').remove();
        }
        
        window.track_event("open auth error modal", { stage_name: 'intercept', action_value: 'error' }, true, true);
        //show auth error popup.
        jQuery('#auth-error-modal').modal('show');
        
        return of(null);
      }
    }
    return throwError(error);
  }
}
