import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LanguageInit } from "framework/i18n/language.init";
import { LocalStorageService } from "framework/localstorage.service";
import { HotjarService } from "./hotjar.service";
import { jwtDecode } from 'jwt-decode';
import { SharedService } from "./shared/services/shared.service";


@Injectable({
    providedIn: 'root'
})
export class AppInit {
    constructor(private languageInit: LanguageInit,
                private localStorage: LocalStorageService,
                private shareService: SharedService,
                private _router: Router,
                private _hotjar: HotjarService) {
      // MFA  redirect , capture the tokens and route to mfa call back.
      this.localStorage.clear();
      this._hotjar.init();
      
      if (window.location.href.indexOf('access_token') > 0 && window.location.href.indexOf('id_token') > 0) {
        this.localStorage.setItem('access_token' ,
                                  window.location.href.substring(window.location.href.indexOf('access_token')  + 13,
                                  window.location.href.indexOf('token_type')  - 1));

        this.localStorage.setItem('id_token',
                                  window.location.href.substring(window.location.href.indexOf('id_token')  + 9,
                                  window.location.href.indexOf('access_token')  - 1)
        );

        this.localStorage.setItem('stateId',
                                  window.location.href.substring(window.location.href.indexOf('state')  + 6,
                                  window.location.href.indexOf('state')  + 42)
          );
          
        this.localStorage.setItem('callback-hash', window.location.hash);
        
        this._router.navigate(['/mfa-callback']);
      } else if ((window.location.href.indexOf('type') > 0) || window.location.href.indexOf('#state=') > 0 || window.location.href.indexOf('#error=') > 0) {
        const query = new URLSearchParams(window.location.search);
        if (!!query && query.has('type')) {
          let authyToken = query.get('token');
          if (!!authyToken) {
            const decodedToken = jwtDecode(authyToken);
            window.sessionStorage.setItem("Authy_subject", decodedToken.sub);
            this.shareService.logAuthyFlow("app", "authy_token_received", "type " + query.get("type"));
          }
        }
        this.localStorage.setItem("isAuthyCallback", true);
      }

      if(window.location.href.indexOf('invite_key') > 0) {
        const invite_key = window.location.href.substring(window.location.href.indexOf('invite_key')  + 10);
        this._router.navigate(['/mfa-logout/'+invite_key]);
      }
    }

    async initialize() {
      // console.log("app.init initialize start---");
      await this.languageInit.initialize();
      // console.log("app.init initialize finish---");
    }
}
