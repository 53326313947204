import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
// import { tokenNotExpired } from '@auth0/angular-jwt';
import { map, catchError } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { SharedService } from 'app/shared/services/shared.service';
import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';

declare var window: any;

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;
  headerOptions: any;
  oktaSecurityKey: string;
  oktaClientId: string;
  oktaBaseUrl: string;

  constructor(private _authHttp: HttpClient, private _router: Router, private localStorage: LocalStorageService,
    private _workflow: WorkflowService, private shareService: SharedService) {

      this.oktaSecurityKey = environment.okta_security_key;
      this.oktaClientId = environment.okta_client_id;
      this.oktaBaseUrl = environment.okta_base_url;
     }

  activateMfa(passcode: string, factorObj: Object): Observable<Object> {
    let body = {
      factorType: (factorObj['factorType'] || ''),
      factorId: factorObj['id'] || '',
      stateToken: factorObj['stateToken'] || '',
      passcode: passcode,
      systemId: this.localStorage.getItem('system_id')
    };

    let traces = {};
    traces['action'] = 'SignIn-Enroll-MFA';
    this.headerOptions = this._workflow.getOIDCHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/factor-activate', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => {
          this.localStorage.setItem('oidc_session',res);
          return res;
        }),
        catchError(err => this._handleActivateError('session api failed', err['error']))
      );
  }

  updateAuthyRegisteredAndSession(inviteKey: string): Observable<Object> {

    let trackObj = {
      stage_name: 'authy',
      action_value: 'link verified'
    };
    window.track_event('update authy session to backend', trackObj, true, true);

    let body = {
      key:inviteKey 
    };

    let traces = {};
    traces['action'] = 'update-authy-session';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/update-session', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => {
          this.localStorage.setItem('authy_session',res);
          return res;
        }),
        catchError(err => this._handleError('authy update-session api failed', err))
        
      );
  }
  enrollMfa(factorObj: Object): Observable<Object> {
    let body = {
      systemId: this.localStorage.getItem('system_id'),
      provider: (factorObj['provider'] || ''),
      stateToken: this.localStorage.getItem('oidc_st'),
      factorType: (factorObj['factorType'] || '')
    };

    let traces = {};
    traces['action'] = 'SignIn-Enroll-MFA';
    this.headerOptions = this._workflow.getOIDCHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/factor-enroll', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => {
          this.localStorage.setItem('oidc_enroll',res);
          return res;
        }),
        catchError(err => this._handleEnrollError('session api failed', err))
      );
  }

  oidcLogin(user: Object): Observable<Object> {
    this.localStorage.setItem('stateId', uuidv4());
    console.log('stateId', this.localStorage.getItem('stateId'));
    let mfaValue = !!this.localStorage.getItem('pa_enable_mfa') ? this.localStorage.getItem('pa_enable_mfa'): '';
    
    let body = {
      "username": user['email'],
      "password": user['password'],
      "sessionId" : this.localStorage.getItem('stateId'),
      "additionalProperties": {
        "email": user['email'],
        "key": this.localStorage.getItem('invite_key'),
        "profile_id" : this.localStorage.getItem('profile_id'),
        "invite_id" :  this.localStorage.getItem('invite_id'),
        "custom_styles": this.localStorage.getItem('custom_styles'),
        "enableMfa": mfaValue
      }
    };

    this.localStorage.setItem('user_email', user['email']);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getOIDCHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/login', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map(res => this._doOidcAction(res)),
        catchError(err => this._handleOIDCLoginError('session api failed', err))
      );
  }

  login(user: Object): Observable<Object> {
    let body = {
      "username": user['email'],
      "password": user['password'],
    };

    this.localStorage.setItem('user_email', user['email']);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/session', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => this._doAction(res)),
        catchError(err => this._handleError('session api failed', err))
      );
  }

  /**
   * added to call session v2 api
   * 
   * @param user 
   * @returns 
   */
  postLogin(user: Object): Observable<Object> {
    let body = {
      "username": user['email'],
      "password": user['password'],
    };

    body['key'] = this.localStorage.getItem('key'); 

    this.localStorage.setItem('user_email', user['email']);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v2/session/api/auth/session', body)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => this._doAction(res)),
        catchError(err => this._handleError('session api failed', err))
      );
  }

  getUserAuthEngine(emailId: string): Observable<Object> {
    let body = {
      "username": emailId
    };

    this.localStorage.setItem('user_email', emailId);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v2/session/api/auth/engine', body)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => this._doActionForForgotPasswordAuthEngine(res)),
        catchError(err => this._handleError('session api failed', err))
      );
  }

  checkLoggedIn(): boolean {
    // let status = (!!this.localStorage.getItem('access_token') &&
    //               tokenNotExpired(this.localStorage.getItem('access_token')) !== true);
    let status = (!!this.localStorage.getItem('access_token'));
    return status;
  }

  logout(): void {
    const sessionState = this.localStorage.getItem('session_state');
    if(!!sessionState) {
      this._authHttp.delete(AppConfig.API_ENDPOINT() + '/api/v1/session/' + this.localStorage.getItem('session_state')).subscribe(response => {
        console.log("Session is deleted.");
      }, error => {
        console.log("Error in logout of the session");
      });
    } else {
      console.log("Session state not found.");
    }
    
    this.isLoggedIn = false;
    // Setting language item after clear so it will reflect in forgot password..
    const existingLanguage = this.localStorage.getItem('language');
    //Logging out okta.
    if( undefined !== this.localStorage.getItem('id_token')){
      this.oktaBaseUrl = environment.okta_base_url;
      const localUrl = new URL(window.location.href);
      const postLogoutRedirectUrl = encodeURI(localUrl.origin);
      const inviteKey = this.localStorage.getItem("invite_key");
      let state = "invite_key"+inviteKey;
      let oktaCallBackUrl = this.oktaBaseUrl + "/oauth2/" + this.oktaSecurityKey + "/v1/logout?client_id=" + this.oktaClientId + "&id_token_hint=" + this.localStorage.getItem('id_token') + "&state=" + state +"&post_logout_redirect_uri=" + postLogoutRedirectUrl;
      console.log("Logout url : ", oktaCallBackUrl);
      window.location.href = oktaCallBackUrl;
    }
    this.shareService.customFormVariables = {};
    // Clear local storage
    this.localStorage.clear();
    this.localStorage.setItem('language', existingLanguage);
  }

  fetchSessionData(): Observable<object>{
    this.headerOptions = this.getHeaderOptions();

    return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/authn/fetch-session-data' , this.headerOptions)
      .pipe(
        map(this._extractServerResponse),
        catchError(err => this._handleError('Fetch session data error.', err))
      );
  }

  getHeaderOptions(): any {
    const authHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Authorization': 'Bearer ' + this.localStorage.getItem('access_token'),
      'id' : this.localStorage.getItem('stateId')
    });

    const OPTIONS = { headers: authHeaders };

    return OPTIONS;
  }

  sendStateToServer(data: Object): Observable<Object> {
    let body: Object = {};
    let actionURL = data['action'];

    let dataFormation = {
      "current_stage": data["name"],
      "current_status": data["status"],
      "current_step": data["step"],
      "current_step_index": data["stepIndex"],
      "current_sub_step": data["subStep"],
      "current_sub_step_index": data["subStepIndex"],
      "current_iteration": data["iteration"],
    };

    /*
     * Sending data format changes as for Residential only otherwise it fails in the BE and hence
     * do not get appropriate Stage Step Details when user logs in back.
    */
    if (data['name'] === 'residential-data-v1') {
      body["address-data-list"] = [dataFormation];
    } else {
      body = dataFormation;
    }

    return this._authHttp.put(AppConfig.API_ENDPOINT() + actionURL, body)
      .pipe(
        map(this._extractServerResponse),
        map(this._doStateAction),
        catchError(err => this._handleError('send state to server failed', err))
      );
  }

  private _extractData(res: Object) {
    return (res) || {};
  }

  private _extractServerResponse(res: any) {
    return res;
  }

  public _clearOidcData() {
    try {
      // TODO: Remove extra logging statements before release
      console.log('Clearing OIDC Data');
      this.localStorage.removeItem('oidc_lgn_res');
    }
    catch(e) {
      console.log(e);
    }
  }

  public _doOidcAction(response: Object) {
    console.log("res is: "+response);
    if(!!response && !!response['systemId'] && !!response['stateToken'] && !!response['status'] && !!response['factors'] && !!response['factors']['0'] && !!response['user']) {
      this.localStorage.setItem("oidc_lgn_res",response);
      this.localStorage.setItem('oidc_st',response['stateToken']);
      this.localStorage.setItem("profile_id",(response['systemId'] || response['system_id']));
      
      return response['stateToken'];
    }
    else if (!!response && !!response['accessToken']) {
      this.localStorage.setItem('access_token', response['accessToken']);
      this.localStorage.setItem('profile_id', response["attributes"]['additionalProperties']['profile_id'][0]);
      this.localStorage.setItem('session_timeout', response['sessionTimeout']);
      this.localStorage.setItem('invite_app_status', '');
      this.localStorage.setItem('session_id', response['id']);
      this.localStorage.setItem('session_state', response['sessionState']);
      this.localStorage.setItem('secret_question_set', '' + response['secretQuestionSet']);
      this.isLoggedIn = true;
      this._workflow.getHeaderOptions();
      
      return response;
    } else {
      return null;
    }
  }

  private _doAction(response: Object) {
    if (!!response && !response['error'] && !this.isMFAError(response)) {
      if (!!response['factors'] && !!response['factors'][0]) {
        return this.setOidcSessionProperties(response);
      } else {
        this.isLoggedIn = true;
        this.setKeycloakSessionProperties(response);
        this._workflow.getHeaderOptions();
        return response['id'];
      }
    } else {
      return response;
    }
  }

  private isMFAError(error: {}) {
    if(!!error && !!error['errorCode']) {
      return !!(!!error && !!error['additionalProperties'] && !!error['additionalProperties']['additional_error_detail'] && !!error['additionalProperties']['additional_error_detail']['action'] && error['additionalProperties']['additional_error_detail']['action']==='INVALID_PASSWORD_UP');
    }
    
    return false;
  }

  private _doActionForForgotPasswordAuthEngine(response: Object) {
    if (!!response && !response['error']) {
      if (!!response && !!response["additional_properties"]) {
        this.localStorage.setItem("custom_styles",response["additional_properties"]['custom_styles']);
        this.localStorage.setItem("auth_engine",response["additional_properties"]['auth_engine']);
      }
      
    }
    
    return response;
  }

  private setOidcSessionProperties(response) {

    this.localStorage.setItem("system_id", !!response ? response['system_id'] : null);

    if (!!response && !!response["additional_properties"]) {// set invite details into local stage
      this.localStorage.setItem("invite_key",response["additional_properties"]['invite_key']);
      this.localStorage.setItem("profile_id",response["additional_properties"]['profile_id']);
      this.localStorage.setItem("invite_id",response["additional_properties"]['invite_id']);
      this.localStorage.setItem("custom_styles",response["additional_properties"]['custom_styles']);
      this.localStorage.setItem('stateId', response["additional_properties"]['session_id']);
    }
    
    if(!!response && !!response['system_id'] && !!response['state_token'] && !!response['status'] && !!response['factors'] && !!response['factors']['0'] && !!response['user']) {
      // update some property to support new implementation
      let factors = response['factors'];
      for (const factor of factors) {
        factor['factorType'] = factor['factor_type'];
        factor['vendorName'] = factor['vendor_name'];
        factor['mobilePhone'] = factor['mobile_phone'];
      }
      
      this.localStorage.setItem("oidc_lgn_res",response);
      this.localStorage.setItem('oidc_st',response['state_token']);
      this.localStorage.setItem('is_oidc_login', true);
      
      return response['state_token'];
    }
    else if (!!response && !!response['access_token']) {
      this.localStorage.setItem('access_token', response['access_token']);
      this.localStorage.setItem('profile_id', response["attributes"]['additional_properties']['profile_id'][0]);
      this.localStorage.setItem('session_timeout', response['session_timeout']);
      this.localStorage.setItem('invite_app_status', '');
      this.localStorage.setItem('session_id', response['id']);
      this.localStorage.setItem('session_state', response['session_state']);
      this.localStorage.setItem('secret_question_set', '' + response['secret_question_set']);
      this.localStorage.setItem('is_oidc_login', true);
      this.isLoggedIn = true;
      this._workflow.getHeaderOptions();
      
      return response;
    } else {
      return null;
    }
  }

  private setKeycloakSessionProperties(response) {
    this.localStorage.setItem('access_token', response['access_token']);
    this.localStorage.setItem('profile_id', response["attributes"]['profile_id'][0]);
    this.localStorage.setItem('session_timeout', response['session_timeout']);
    this.localStorage.setItem('invite_app_status', '');
    this.localStorage.setItem('session_id', response['id']);
    this.localStorage.setItem('session_state', response['session_state']);
    this.localStorage.setItem('secret_question_set', '' + response['secret_question_set']);
    console.log("session_state :", response['session_state']);
  }

  private _doStateAction(response: any) {
    return true;
  }

  private _handleError(eventName, error: any) {
    let errorMessage: string;
    let trackObj = {
      stage_name: 'login',
      action_value: 'error'
    };
    window.track_event(eventName, trackObj, true, true, true);

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
      case 405:
      case 403:
        //Bad request, Invalid Credentials - login invalid
        let _body = error|| {};
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;

      case 404:
        break;
      case 423:
        //errorMessage = error['error']['error'];
        errorMessage = 'ACCOUNT_LOCKED';
        break;
    }
    return throwError(errorMessage);
  }

  private _handleActivateError(eventName, error: any) {
    let errorMessage: string;
    // debugger
    console['server'](eventName, error);

    errorMessage = !!error.errorCode ? 'OKTA_ERROR_' + error.errorCode : 'ERROR_OKTA_INVALID_STATE';

    return throwError(errorMessage);
  }

  private _handleEnrollError(eventName, error: any) {
    let errorMessage: string;
    // debugger
    console['server'](eventName, error);

    switch(error.status) {
      case 405:
          if (!!error && !!error.error && error.error.errorCode === 'OI407' && !!error.error.additionalProperties && !!error.error.additionalProperties.error_details &&
            error.error.additionalProperties.error_details.errorCode === 'E0000109')
            errorMessage = 'ERROR_SMS_RECENTLY_SENT';
          else
            errorMessage = "ERROR_OKTA_INVALID_STATE";

          break;
      default: errorMessage = "ERROR_OKTA_INVALID_STATE";
              break;
    }

    return throwError(errorMessage);
  }

  migrateToOkta(user): Observable<unknown> {
    const actionUrl = '/api/v1/authn/migrate';
    this.localStorage.setItem('stateId', uuidv4());

    const body = {
      username: user.email,
      password: user.password,
      sessionId: this.localStorage.getItem('stateId'),
      additionalProperties: {
        key: this.localStorage.getItem('invite_key'),
        profile_id: this.localStorage.getItem('profile_id'),
        invite_id: this.localStorage.getItem('invite_id'),
        email: this.localStorage.getItem('applicant_email')
      }
    };

    return this._authHttp.post(AppConfig.API_ENDPOINT() + actionUrl, body)
      .pipe(
        map((response) => response),
        map((response) => this._doOidcAction(response)),
        catchError((err) => this._handleOIDCLoginError('migrate failed', err)),
      );
  }

  oidcForgotPasswordFlowResend(emailID, selectedFactor): Observable<unknown> {
    const body = {
      "username": emailID,
      "factorType" : selectedFactor
    };

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/forgot-password/resend', body)
      .pipe(
        map((response) => response),
        map((response) => response),
        catchError(err => this._handleEnrollError('oidc_forgot_password_failed', err))
      );
  }

  oidcFactorResend(factorObject) {
    const body = {
      factorId: factorObject.id || '',
      stateToken: factorObject.stateToken || ''
    };

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/resend-otp', body)
      .pipe(
        map((response) => response),
        map((response) => response),
        catchError(err => this._handleResendError('oidc_resend_failed', err))
      );
  }

  oidcForgotPassword(emailID, selectedFactor): Observable<unknown> {
    const body = {
      "username": emailID,
      "factorType" : selectedFactor
    }

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/forgot-password', body)
      .pipe(
        map((response) => response),
        map((response) => this._doActionForOidcForgotPassword(response)),
        catchError(err => this._handleEnrollError('oidc_forgot_password_failed', err))
      );
  }

  private _doActionForOidcForgotPassword(response: Object) {
    if (!!response) {
      this.localStorage.setItem("oidc_forgot_password_init", response);
    }

    return response;
  }

  private _handleResendError(eventName, error: any) {
    let errorMessage: string;
    console['server'](eventName, error);

    switch(error.status) {
      case 405:
          errorMessage = 'ERROR_OKTA_INVALID_STATE';
          break;
      case 500:
          if (!!error.error && error.error.errorSummary.indexOf('E0000011') > -1) {
            errorMessage = 'ERROR_SESSION_EXPIRED';
          } else {
            errorMessage = 'ERROR_OKTA_INVALID_STATE';
          }

          break;
      default: errorMessage = 'ERROR_OKTA_INVALID_STATE';
              break;
    }

    return throwError(errorMessage);
  }

  private _handleOIDCLoginError(eventName, error: any) {
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
      case 405:
      case 403:
        //Bad request, Invalid Credentials - login invalid
        let _body = error || {};

        errorMessage = _body['error']['error'] || 'Invalid Login';

        let errorDetails = null;
        if(!!error && !!error.error && !!error.error.additionalProperties) {
          errorDetails = error.error.additionalProperties;
        }
        else if(!!error && !!error.additionalProperties) {
          errorDetails = error.additionalProperties;
        }

        if (!!errorDetails && !!errorDetails.additional_error_detail && errorDetails.additional_error_detail['action'] === 'INVALID_PASSWORD_UP') {
          if (errorDetails.additional_error_detail.pendingInvalidPwdCount === "0")
            errorMessage = 'ACCOUNT_LOCKED';
          else
            errorMessage = 'INVALID_LOGIN#' + errorDetails.additional_error_detail.pendingInvalidPwdCount;
        }

        break;

      case 404:
        break;
      case 423:
        //errorMessage = error['error']['error'];
        errorMessage = 'ACCOUNT_LOCKED';
        break;
    }
    return throwError(errorMessage);
  }

  validateForgotPasswordOTP(requestBody) {
    return this._authHttp
      .post(AppConfig.API_ENDPOINT() + `/api/v1/authn/validate-forgotpassword-otp`, requestBody, this.headerOptions)
      .pipe(
        map(res => this._doActionForOidcValidateForgotPassword(res)),
        catchError((error: any) => {
          let errorMessage: string;
          console['server']('reset_pwd_failed', error);

          errorMessage = !!error.error?.errorCode ? 'OKTA_ERROR_' + error.error.errorCode : 'ERROR_OKTA_INVALID_STATE';

          return throwError(errorMessage);
        })
      )
  }

  private _doActionForOidcValidateForgotPassword(response: Object) {
    if (!!response) {
      this.localStorage.setItem("oidc_reset_password_init", response);
    }

    return response;
  }

}
