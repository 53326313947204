<div [formGroup]="parentForm">
  <input [attr.id]="countryPickerConfigOptions['ATTRIBUTE_ID']" aria-required="true" class="test m-b-20" type="text"
    [maxlength]="countryPickerConfigOptions['MAX_LENGTH']" placeholder="" class="form-control captext"
    [formControlName]="countryPickerConfigOptions['FORM_CONTROL_NAME']" autocomplete="off"
    [class.gray50]="countryPickerConfigOptions['CLASS_BOOLEAN']"
    [class.gray80]="!countryPickerConfigOptions['CLASS_BOOLEAN']" [attr.readonly]="!!isReadOnly ? '' : null"
    (keyup)="countryStateLookUp(parentForm.controls[countryPickerConfigOptions['FORM_CONTROL_NAME']].value, countryPickerConfigOptions, $event)"
    (focus)="countryStateLookUp(parentForm.controls[countryPickerConfigOptions['FORM_CONTROL_NAME']].value, countryPickerConfigOptions, $event)"
    (blur)="setStateCountryData(countryPickerConfigOptions)" />
  <input type="hidden" [maxlength]="countryPickerConfigOptions['MAX_LENGTH']" placeholder=""
    [formControlName]="countryPickerConfigOptions['HIDDEN_FORM_CONTROL_NAME_1']" />
  <input type="hidden" [maxlength]="countryPickerConfigOptions['MAX_LENGTH']" placeholder=""
    [formControlName]="countryPickerConfigOptions['HIDDEN_FORM_CONTROL_NAME_2']" />
</div>
<ul [class.hide]="isStateListToShow === false" class="generic-dropdown-list">
  <li *ngFor="let stateData of stateList" (click)="enterStateInput(stateData)">
    <div>
      <span>{{stateData['state_name']}}</span>
    </div>
  </li>
</ul>
<ul [class.hide]="isCountryListToShow === false" class="generic-dropdown-list">
  <li *ngFor="let countryData of countryList" (click)="entercountryInput(countryData)">
    <div>
      <span>{{countryData['country_name']}}</span>
    </div>
  </li>
</ul>
