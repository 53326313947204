/*
 * countryPickerConfigOptions Object structure:
 *  {
 *    ATTRIBUTE_ID: 'countryId/addressId',
 *    TYPE: 'text',
 *    OPTIONS: '',
 *    MAX_LENGTH: 50,
 *    FORM_CONTROL_NAME: 'DDDDD',
 *    CLASS_BOOLEAN: true | false,
 *    HIDDEN_FORM_CONTROL_NAME_1: 'HHHHHHH',
 *    HIDDEN_FORM_CONTROL_NAME_2: 'AAAAAA'
 * }
 *
 * Country :: Output Object Structure
 * {
 *    COUNTRY_LONG_NAME: 'XXX',
 *    COUNTRY_SHORT_NAME: 'xxx'
 * }
 *
*/
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, Renderer2, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CountryPickerService } from './country-picker.service';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from './../../../shared/services/shared.service';

declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'country-picker',
  templateUrl: './country-picker.component.html',
  providers: [CountryPickerService],
})

export class CountryPickerComponent {
  @Input('parent')
  public parentForm: FormGroup;

  @Input() countryPickerConfigOptions: any;
  @Input() isReadOnly: boolean;

  private _data = new BehaviorSubject<any>([]);
  private _clearCountryList = new BehaviorSubject<any>([]);
  private _clearStateList = new BehaviorSubject<any>([]);

  @Input()
  set selectedCountry(value: string) {
    if (value !== '') {
      this._data.next(value);
    }
  };

  get selectedCountry() {
    return this._data.getValue();
  }

  @Input()
  set inputClearCountryList(value: string) {
    if (value !== '') {
      this._clearCountryList.next(value);
    }
  };

  get inputClearCountryList() {
    return this._clearCountryList.getValue();
  }

  @Input()
  set inputClearStateList(value: string) {
    if (value !== '') {
      this._clearStateList.next(value);
    }
  };

  get inputClearStateList() {
    return this._clearStateList.getValue();
  }

  @Output() onCountrySelection: EventEmitter<any> = new EventEmitter();

  attributeID: string;
  countryControlName: string;
  maxLength: string;
  hiddenCountryNameControl: string;
  hiddenCountryCodeControl: string;
  booleanClass: boolean;
  static countryName: string;

  stateList: any = [];
  countryList: any = [];

  isCountryListToShow: boolean = false;
  isStateListToShow: boolean = false;

  stateCode: string = '';
  input;
  autoComplete;
  countryShortName;

  constructor(private _renderer: Renderer2,
    private _countryPickerService: CountryPickerService,
    private shareService: SharedService) {
  }

  ngOnInit(): void {
    console.log('country picker ngOnInit');

    //console.log("form control name" + JSON.stringify(this.parentForm));

    this._data
      .subscribe(x => {
        console.log('COUNTRY PICKER COUNTRY CHANGED ---- ' + x);
        //CountryPickerComponent.countryName = (x !== undefined && x.length > 0) ? x.split('|')[0] : '';
        this.countryShortName = (x !== undefined && x.length > 0) ? x : 'US';
      });

    // Clear country dropdown list
    this._clearCountryList
      .subscribe(res => {
        if (res !== undefined && res !== '' && res !== null) {
          this.isCountryListToShow = false;
        }
      });

    // Clear state dropdown list
    this._clearStateList
      .subscribe(res => {
        if (res !== undefined && res !== '' && res !== null) {
          this.isStateListToShow = false;
        }
      });
    this.attributeID = this.countryPickerConfigOptions['ATTRIBUTE_ID'];
    this.countryControlName = this.countryPickerConfigOptions['FORM_CONTROL_NAME'];
    this.maxLength = this.countryPickerConfigOptions['MAX_LENGTH'];
    this.hiddenCountryNameControl = this.countryPickerConfigOptions['HIDDEN_FORM_CONTROL_NAME_1'];
    this.hiddenCountryCodeControl = this.countryPickerConfigOptions['HIDDEN_FORM_CONTROL_NAME_2'];
    this.booleanClass = this.countryPickerConfigOptions['CLASS_BOOLEAN'];
  }

  ngAfterViewInit() {
  }

  ngOnChange(change: SimpleChanges) {
  }

  ngDestroy() {
  }

  // get lookup service for both country and state
  countryStateLookUp(data, countryPickerConfigOptions, event) {

    this._renderer.setStyle(event.target, this.countryPickerConfigOptions['CSS_CLASS'], true);

    let flag = countryPickerConfigOptions['FORM_CONTROL_NAME'];
    if (flag === 'country') {
      let queryParam = {
        'countryParam': data.trim()
      };

      // call shared service to get state datas
      this.shareService.countryCodeLookupSwitch(queryParam).subscribe(response => {
        let countryData = response;
        // console.log('country Data: ' + JSON.stringify(countryData));
        this.countryList = countryData['country_list'];
        if (this.countryList.length > 0) {
          this.isCountryListToShow = true;
        } else {
          this.isCountryListToShow = false;
        }
      }, error => {

      });
    } else if (flag === 'state') {
      let queryParam = {
        'countryCode': this.countryShortName,
        'stateParam': data.trim()
      };

      // call shared service to get state datas
      this.shareService.stateLookupSwitch(queryParam).subscribe(response => {
        let stateData = response;
        // console.log('state Data: ' + JSON.stringify(stateData));
        this.stateList = stateData['state-data-list'];
        if (this.stateList.length > 0) {
          this.isStateListToShow = true;
        } else {
          this.isStateListToShow = false;
        }
      }, error => {

      });
    }
  }

  /* ------------- Bind methods start here ------------- */
  enterStateInput(data) {
    this.isStateListToShow = false;
    this.stateCode = data['state_code'];

    this.onCountrySelection.emit({
      'LONG_NAME': data['state_name'].replace(/\b\w/g, l => l.toUpperCase()),
      'SHORT_NAME': data['state_code'].toUpperCase()
    });
  }

  entercountryInput(data) {
    // console.log('data: ' + JSON.stringify(data));
    this.isCountryListToShow = false;

    this.onCountrySelection.emit({
      'LONG_NAME': data['country_name'],
      'SHORT_NAME': data['country_code']
    });
  }

  setStateCountryData(countryPickerConfigOptions) {
    let flag = countryPickerConfigOptions['FORM_CONTROL_NAME'];
    if (flag === 'country') {
      if (this.countryList.length === 1 && this.isCountryListToShow === true) {
        this.entercountryInput(this.countryList[0]);
      }
    } else if (flag === 'state') {
      if (this.stateList.length === 1 && this.isStateListToShow === true) {
        this.enterStateInput(this.stateList[0]);
      }
    }
  }

  //Need to define parameter types
  private placeEvent(place, type): void {
    let matches = [];
    let city = [];
    let state = [];
    let postalCode = [];
    let route = [];
    let addressCollection = [];
    let countryLName;
    let countrySName;
    let stateLongname;
    let stateShortname;


    if (place && place.address_components && place.address_components.length > 0) {
      for (let i = 0; i < place.address_components.length; i += 1) {
        let addressObj = place.address_components[i];

        for (let j = 0; j < addressObj.types.length; j += 1) {
          /*
           * Country processing
          */
          if (type === "country") {
            if (addressObj.types[j] === "country") {
              if (matches.indexOf(addressObj.long_name) === -1) {
                countryLName = addressObj.long_name.toLocaleLowerCase();
                countrySName = addressObj.short_name.toLocaleLowerCase();

                //If both Long & Short Names are equal
                if (countrySName === countryLName) {
                  this._countryPickerService.lookUpService(countryLName).subscribe(response => {
                    let data = response;
                    countrySName = addressObj.short_name = data['country_code'];
                    matches.push({ long_name: data['country_name'], short_name: data['country_code'] });
                    this.onCountrySelection.emit({
                      'LONG_NAME': matches[0]["long_name"],
                      'SHORT_NAME': matches[0]["short_name"]
                    });
                  }, error => {
                  });
                } else {
                  matches.push({ long_name: addressObj.long_name, short_name: addressObj.short_name });
                  this.onCountrySelection.emit({
                    'LONG_NAME': matches[0]["long_name"],
                    'SHORT_NAME': matches[0]["short_name"]
                  });
                }
              }
            }
          }
          /*
           * State processing
          */
          if (type === "state") {
            if (addressObj.types[j] === "administrative_area_level_1" ||
              addressObj.types[j] === "locality") {
              stateShortname = addressObj["short_name"].toLocaleLowerCase();
              stateLongname = addressObj["long_name"].toLocaleLowerCase();

              if (stateShortname === stateLongname) {
                let body = CountryPickerComponent.countryName + "&state=" + stateLongname
                this._countryPickerService.lookUpService(body).subscribe(response => {
                  let data = response;
                  if (data['state_code'] != null || data['state_code'] != undefined) {
                    addressObj["short_name"] = data['state_code'];
                  }
                  matches.push({ long_name: data['state_name'], short_name: data['state_code'] });

                  this.onCountrySelection.emit({
                    'LONG_NAME': matches[0]["long_name"].replace(/\b\w/g, l => l.toUpperCase()),
                    'SHORT_NAME': matches[0]["short_name"].toUpperCase()
                  });
                },
                  error => {
                  });
              } else {
                matches.push({ long_name: stateLongname, short_name: stateShortname });
                this.onCountrySelection.emit({
                  'LONG_NAME': matches[0]["long_name"].replace(/\b\w/g, l => l.toUpperCase()),
                  'SHORT_NAME': matches[0]["short_name"].toUpperCase()
                });
              }
            }
          }
        }
      }
    }
  }
}
