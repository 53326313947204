import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfig } from '../../app.config';
import { WorkflowService } from '../../../framework/workflow.service';
import { LocalStorageService } from '../../../framework/localstorage.service';
import { UtilService } from "../../../framework/utils/util.service";
import * as dateUtils from '../../../framework/utils/date.utils';
import { environment } from '../../../environments/environment';

import { TranslateService } from 'framework/i18n';
import { EventBusService } from './../../../framework/eventbus.service';
import { Subject } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LanguageInit } from 'framework/i18n/language.init';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppConstants } from 'app/app.constants';
import { AuthService } from 'app/login';
import { SharedConfig } from '../shared.config';
import { HotjarService } from 'app/hotjar.service';
import { ExceptionService } from 'framework/exception/exception.service';

declare var window: any;
@Injectable()
export class SharedService {
  customFormVariables: any;
  headerOptions: any;
  selectedCountryCode: string;
  aliasDateArray: Array<any>;
  currentLanguage: string;
  minDate: any;
  fieldErrorNotified = new Subject<any>();
  fieldErrorNotified$ = this.fieldErrorNotified.asObservable();
  reportingManagerNotified = new Subject<any>();
  reportingManagerNotified$ = this.reportingManagerNotified.asObservable();
  countryNotified = new Subject<any>();
  countryNotified$ = this.countryNotified.asObservable();
  genderNotified = new Subject<any>();
  genderNotified$ = this.genderNotified.asObservable();
  private errorCode: string;
  private errorMessage: string;
  private selectedNotification: any;
  selectedNationality: string;
  payloadForAudits: any;
  lookupBaseUrl: string = '';
  isAzureCognitiveSearch: string = 'N';
  accentsMap: {} = {};
  storeProfileKypAccentsTranslatedData: {} = {};
  storeAddressKypAccentsTranslatedData: {} = {};
  isKypAccentPreferenceEnabled: boolean = false;
  lockedFieldsList: any[];
  sharedDeviceDetails: any = {};

  constructor(private _authHttp: HttpClient,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService,
    private _ts: TranslateService,
    private _utilService: UtilService,
    private _languageInit: LanguageInit,
    private deviceService: DeviceDetectorService,
    private serverLogService: ExceptionService,
    private _hotjar: HotjarService
  ) {
    this.headerOptions = this._workflow.getHeaderOptions();
    this.aliasDateArray = [];
    this.customFormVariables = {};
    this.setDeviceDetails();
    // isBehavioralMetrics (For Hotjar), isOperationalMetrics (For Log audit)
    window.track_event = (name: string, payload: any, isBehavioralMetrics: boolean, isOperationalMetrics: boolean, sendDeviceDetails?: boolean) => {
      setTimeout(() => {
        this.interactWithAnalytics(name, payload, isBehavioralMetrics, isOperationalMetrics, sendDeviceDetails);
      }, 800);
    }
    
    this.isAzureCognitiveSearch = this.localStorage.getItem('azure_cognitive_search_enabled');
    this.isKypAccentPreferenceEnabled = !!this.localStorage.getItem('accent_character_translation_enabled') && this.localStorage.getItem('accent_character_translation_enabled') === 'Y' ? true : false;
    this.lookupBaseUrl = this.localStorage.getItem('lookup_service_url');
    
    if (!this.lookupBaseUrl) {
      this.isAzureCognitiveSearch = 'N';
    }
    
    this.generateAccentsMapping();
  }

  identifyAuthPlatform(): string {
    let selectedPage = 'KEYCLOAK';
    const isMFAEnabled = this.localStorage.getItem('pa_enable_mfa');

    if (!!isMFAEnabled && !!isMFAEnabled.toUpperCase() && 'YES' === isMFAEnabled.toUpperCase()) {
      const authEngine = this.localStorage.getItem('user_auth_engine');
      const authType = this.localStorage.getItem('user_auth_model');

      if (!authType || !authType.toUpperCase()) {
        selectedPage = 'KEYCLOAK';
      } else if (authType.toUpperCase() === 'STANDARD') {
        if (!authEngine || !authEngine.toUpperCase() || authEngine.toUpperCase() === 'KEYCLOAK') {
          selectedPage = 'KEYCLOAK';
        }
        else {
          selectedPage = 'OIDC';
        }
      } else if (authType.toUpperCase() === 'MFA') {
        selectedPage = 'OIDC';
      } else {
        selectedPage = 'KEYCLOAK';
      }
    }
    else {
      selectedPage = 'KEYCLOAK';
    }

    return selectedPage;
  }

  // Load Signature
  getSignatureByWorkflowId(wfId): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let options = Object.assign({ responseType: 'text' }, this.headerOptions);
    return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + wfId + '/signature/?t=' + new Date(), options)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('get_signature_failed', err))
      );
  }

  /**
   * lookup nationality data
   *
   * @param body
   * @returns
   */
  nationalityLookUpService(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let nationalityParam = this.getEncodeUrl(body['nationalityParam']);

    if(this.currentLanguage === undefined){
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    const language = this.getLanguageCodeMapping(this.currentLanguage);

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/nationality/?nationlity_param=${nationalityParam}` + '&language=' + language + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('nationality_lookup_failed', err))
      );
  }

  // Call country lookup service
  countryLookUpService(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let countryParam = this.getEncodeUrl(body['countryParam']);

    if (this.currentLanguage === undefined) {
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    const language = this.getLanguageCodeMapping(this.currentLanguage);

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/country/?code=${countryParam}` + '&language=' + language + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('country_lookup_failed', err))
      );
  }

  /**
   * Function to lookup up countries by 2-digit country code or 3-digit iso code
   * 
   * @param body 
   * @param isLookupByIsoCode 
   * @returns 
   */
  countryLookUpServiceByCode(body, isLookupByIsoCode): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let countryParam = body['countryParam'];

    if(this.currentLanguage === undefined){
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    const language = this.getLanguageCodeMapping(this.currentLanguage);

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/country/?country_code=${countryParam}` + '&language=' + language + 
      '&iso_code_lookup=' + isLookupByIsoCode + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('country_lookup_withcode_failed', err))
      );
  }

  // Call country lookup service By CountryCode
  countryLookUpServiceByCountryCode(body): Observable<any> {
    return this.countryLookUpServiceByCode(body, false);
  }

  getLanguageCodeMapping(languageCode: string) {
    if (!languageCode) {
      return 'en-us';
    }
    
    let languageMap = new Map<string, string>();
    languageMap.set('en-gb', 'en-US');
    languageMap.set('es-419', 'es-MX');
    languageMap.set('es', 'es-MX');
    languageMap.set('fr', 'fr-FR');
    languageMap.set('id', 'en-US');
    languageMap.set('pt', 'pt-PT');

    if (languageMap.has(languageCode)) {
      return languageMap.get(languageCode);
    }

    return languageCode;
  }

  //Used for Validating Country Name..
  lookUpService(country): Observable<any> {
    let countryParam = this.getEncodeUrl(country);
    if(this.currentLanguage === undefined){
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    const language = this.getLanguageCodeMapping(this.currentLanguage);

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/shortcodes/?country=${countryParam}` + '&language=' + language + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('shortcode_lookup_failed', err))
      );
  }

  // Call state lookup service
  stateLookUpService(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let countryCode = body['countryCode'];
    let stateParam = this.getEncodeUrl(body['stateParam']);

    if(this.currentLanguage === undefined){
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    const language = this.getLanguageCodeMapping(this.currentLanguage);

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup-states/?country_code=${countryCode}&state_param=${stateParam}` + '&language=' + language + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('state_lookup_failed', err))
      );
  }

  lookUpStateCodeByName(country: string, state: string): Observable<any> {
    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/state-name-by-code/?country=${country}` + `&state=${state}` + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('state_lookup_byname_failed', err))
      );
  }

  // call zip lookup service
  zipLookUpService(body): Observable<any> {
    let countryCode = body['countryCode'];
    let placeCode = body['placeCode'];
    let zipParam = body['zipParam'];
    let stateCode = body['stateCode'];

    if(this.currentLanguage === undefined){
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    if (countryCode !== 'US') {
      // retun empty array instead of calling service for NON-US countries
      return of({ 'zipcode-data-list': [] });
    }
    
    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup-zipcodes/?country_code=${countryCode}&state_code=${stateCode}&place_code=${placeCode}&zip_param=${zipParam}` + '&language=' + this.currentLanguage + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('zip_lookup_failed', err))
      );
  }

  // Call city lookup service
  cityLookUpService(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let countryCode = body['countryCode'];
    let stateCode = body['stateCode'];
    let cityParam = body['cityParam'];

    if(this.currentLanguage === undefined){
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    if (countryCode !== 'US') {
      // retun empty array instead of calling service for NON-US countries
      return of({ 'city-data-list': [] });
    }

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup-cities/?country_code=${countryCode}&state_code=${stateCode}&city_param=${cityParam}` + '&language=' + this.currentLanguage + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('city_lookup_failed', err))
      );
  }

  // Call country code lookup service
  countryCodeLookUpService(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();

    if(this.currentLanguage === undefined){
      // console.log("Current language is undefined hence resetting it from local storage : " +  this.localStorage.getItem('language'));
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup-isdcodes/?code=${body}` + '&language=' + this.currentLanguage + '&t=' + new Date(), this.headerOptions)
    .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('countrycodes_lookup_failed', err))
      );
  }

  // Call country lookup service
  companyLookUpService(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let countryParam = this.getEncodeUrl(body['country']);
    let stateParam = this.getEncodeUrl(body['state']);
    let typeParam = this.getEncodeUrl(body['type']);
    let companyParam = this.getEncodeUrl(body['company']);
    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/institution/?&country=${countryParam}&state=${stateParam}&data=${companyParam}&datatype=${typeParam}` + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('company_lookup_failed', err))
      );
  }

  // call zip lookup service
  mapLookUpService(body): Observable<any> {
    let address = body['address'];
    let url = 'https://nominatim.openstreetmap.org/search?q=' + address + '&format=json&addressdetails=1&limit=15&countrycodes=us&accept-language=en';

    return this._authHttp.get(url, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('map_lookup_failed', err))
      );
  }

  getLanguageCode(browserLangCode: string): string {
    // 'en' - default language set in App.config
    let langCode: string = AppConfig.getDefaultLanguage();

    // Map the browser language code to PA Defined code, if not found return default
    if (AppConfig.getSupportedLanguageList()[browserLangCode] !== undefined) {
      langCode = AppConfig.getSupportedLanguageList()[browserLangCode];
    }

    return langCode;
  }

  setSelectedCountryCode(code: any): void {
    this.selectedCountryCode = code;
  }

  getSelectedCountryCode(): string {
    return this.selectedCountryCode;
  }

  setSelectedNationality(nationality: any): void {
    this.selectedNationality = nationality;
  }

  getSelectedNationality(): string {
    return this.selectedNationality;
  }

  // Order Creation
  orderCreation(): Observable<any> {
    let body = {
      'invite_id': this.localStorage.getItem('inviteId')
    };
    // this.headerOptions = this._workflow.getHeaderOptions();

    //Add AppD trace for doc upload completion
      let traces = {};
      traces['action'] = 'OrderAttempted';
      this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);


    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/order', body, this.headerOptions)
      .pipe(map((res) => this._extractDataOrderCreation(res)),
        timeout(180000),
        catchError(err => this._handleError('order_creation_failed', err))
      );
  }

  // Put call on order service to update events
  putOrder(eventMsg: string, status: string): Observable<any> {
    let body = {
      'invite_key': this.localStorage.getItem('key'),
      'status': status,
      'event': eventMsg
    };

    this.headerOptions = this._workflow.getHeaderOptions();

    return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/order', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        timeout(180000),
        catchError(err => this._handleError('order_put_failed', err))
      )
  }

  putOrderEiaf(): Observable<any> {
    let body = {
      'invite_key': this.localStorage.getItem('key'),
      'status': 'R'
    };
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/order', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        timeout(180000),
        catchError(err => this._handleError('order_eiaf_put_failed', err))
      )
  }

  // redirect to clipboard when we order is failed(402) from clipboard/document-upload component
  redirectClipboard(body): Observable<any> {
    let workflowid = this.localStorage.getItem('workflow_instance_id');
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/clipboard', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError('redirect_cb_failed', err))
      );
  }

  // Return response
  private _extractData(res: any) {
    return res;
  }

  private _doAction(response: any) {
    return response;
  }

  private _handleError(eventName, error: any) {
    let _body = error || {};
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        // Bad request, Invalid Credentials - login invalid
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;
      case 404:
        break;
    }

    return throwError(errorMessage);
  }

  private _extractDataOrderCreation(res: any) {
    let x = (res) || {};

    if (!!x && !!x['wps-number'] && !!x['is_report_on']) {
      return {
        'WPSNumber': x['wps-number'],
        'is_report_on': x['is_report_on']
      };
    }

    if (!!x && !!x['review-status'] && !!x['is_report_on']) {
      return {
        'reviewStatus': x['review-status'],
        'is_report_on': x['is_report_on']
      };
    }

    if (!!x && !!x['queued_status'] && !!x['is_report_on']) {
      return {
        'queuedStatus': x['queued_status'],
        'is_report_on': x['is_report_on']
      };
    }

  }

  private _handleOrderCreationError(error: any) {
    let _body = error || {};
    let errorCode: number;

    switch (error.status) {
      case 400:
      case 401:
        //Bad request, Invalid Credentials - login invalid
        errorCode = 401;
        break;

      case 404:
        errorCode = 404;
        break;

      case 412:
        errorCode = 412;
        break;
    }
    return throwError(errorCode);
  }

  //method to add alias dates into array to compare with dob
  setAliasArray(obj) {
    if (obj['emptyArray']) {
      this.aliasDateArray = [];
    }
    if (obj.firstname !== undefined && obj.lastname !== undefined &&
      obj.startDate !== undefined && obj.endDate !== undefined) {
      this.aliasDateArray.push(obj);
      this.minDate = this._utilService.setMinDate(this.aliasDateArray);
    }
  }

  /**
   * Setting min date when alias start date is entered from alias component
   * @param obj
   */
  setMinDate(obj: any) {
    if (obj['startDate'] === undefined || obj['startDate'] === null) {
      if (obj['start-date'] !== undefined && (obj['start-date'] !== null && obj['start-date'] != '') && obj['singleAlias']) {
        let date1 = dateUtils.formatMonthDateYear(new Date(obj['start-date']));
        let monthNames: any = this._ts.instant('MONTH_NAMES');
        obj['startDate'] = dateUtils.getDateFormatMonthYear(date1, monthNames);
        this.minDate = obj['startDate'];
      } else {
        if (obj['fromGender']) {
          if (obj['firstIteration']) {
            this._utilService.minDate = '';
          }
          this.minDate = this._utilService.checkMinStartDate(obj);
        } else {
          this.minDate = {};
        }
      }
    }
    else {
      this.minDate = this._utilService.checkMinStartDate(obj);
    }
  }

  /**
    * Setting min date when alias start date is modified switching from clipboard component
    * @param obj
    * @param stDate
    */
  setMinDateFromReview(obj: any, stDate) {
    let monthNames: any = this._ts.instant('MONTH_NAMES');
    this._utilService.setMonthNames(monthNames);
    let retObj = this._utilService.setMinDateFromReview(this.aliasDateArray, obj, stDate);
    this.aliasDateArray = retObj['updatedArray'];
    this.minDate = retObj['mindate'];
  }

  getAliasArray() {
    return this.aliasDateArray;
  }

  async setLanguage(response: Object, eventBus: any): Promise<void> {

    // Check whether language is coming along with country code EX:en-gb
    if (!!response['isLangCodeWithCountryCode']) {

      // Split language code and country code
      response = this.makeLanguageResponseObj(response['languageWithCountryCode']);
    }

    /*
    * Lanugage setting - Browser takes precedence.
    */
    if (AppConfig.LANGUAGE_PRECENDENCE !== 'browser') {
      let language = this.prepareLanguage(response);
      // console.log('Invite Language is ', language);

      // Set the Language value received in INVITE
      //this.setLanguageBasedOnInvite(language);
      await this.switchLanguage(language);

      /**
       * Footer links to respond to the language received from INVITE
       * service.
       */

      this.informOthersLanguageReceived(eventBus, language);
    } else {
      // console.log('#--> Changing to ', navigator.language.toLowerCase());
      //this.setDefaultLang(navigator.language.toLowerCase());
      await this.switchLanguage(navigator.language.toLowerCase());
    }
  }

  makeLanguageResponseObj(data) {
    let splitData = data.split('-');
    return { 'language': splitData[0], 'language_country_code': splitData[1] };
  }

  prepareLanguage(inviteResponse: any): string {
    let languageValue: string;
    let language: string = (inviteResponse['language'] !== undefined) ? (inviteResponse['language']).toLowerCase() : (AppConfig.APP_CONSTANTS.LANGUAGE_EN).toLowerCase();
    let languageCode = (inviteResponse['language_country_code'] !== undefined) ?
      (inviteResponse['language_country_code']).toLowerCase() : undefined;

    languageValue = language;

    /**
     * Use language_country_code for non us countries and non en
     */

    if (languageCode !== undefined &&
      languageCode !== (AppConfig.APP_CONSTANTS.DOMESTIC_COUNTRY_CODE).toLowerCase()) {
      languageValue += ('-' + languageCode);
    }
    return languageValue;
  }

  setLanguageBasedOnInvite(languageSetInInvite): void {
      // console.log(' #--> Setting language based on invite : ' + languageSetInInvite);
      this.setDefaultLang(languageSetInInvite);
  }

  setDefaultLang(language: string) {
    // console.log('Setting Default Language : ' + language);
    const languageValue: string = this.validateAndReturnLanguageCode(language);
    this.setLanguageValue(languageValue);
  }

  setLanguageValue(languageValue: string) {
    this.selectLang(languageValue);
    // Set in local storage for Application-wide re-use
    this.localStorage.setItem('language', languageValue);
    // Value to be used in Country/State Lookup
    this.currentLanguage = languageValue;
  }

  async switchLanguage(language: string) {
    await new Promise(async (resolve, reject) => {
      const languageValue: string = this.validateAndReturnLanguageCode(language);
      await this._languageInit
        .useLanguage(languageValue)
        .then((lang) => {
          this.setLanguageValue(languageValue);
          resolve(true);
        })
        .catch(err => {
          // reset
          // console.log("Issue with switchLanguage ", language, err);
          reject(false);
        })
    });
  }

  selectLang(lang: string) {
    //set original language before switching to zulu for crowdin version
    // to load translations from db
    this._ts.setOriginalLanguageCode(lang);

    if (environment &&
      environment.hasOwnProperty('languageEditEnabled') &&
      environment.languageEditEnabled === true) {
        // console.log(' #--> Setting zulu default lang for language edit mode.');
        this._ts.use('zu');
      } else {
        // set default;
        // console.log(' #--> PROD mode - setting invite language : ' + lang);
        this._ts.use(lang);
      }
  }

  validateAndReturnLanguageCode(selectedLang: string): string {
    return (selectedLang !== undefined && selectedLang !== '') ? this.getLanguageCode(selectedLang.toLowerCase()) : AppConfig.getDefaultLanguage();
  }

  // set language list
  setAvilableLanguageList(response, eventBus: any) {
    let availLangList = response['account']['available_languages'];
    this.localStorage.setItem('availableLanguageList', availLangList);
  }

  /**
   * update invite language without authentication
   * 
   * @param body - key, language, language_country_code.
   * update db with updated language(user selected language).
  */
  updateLanguageOnServer(body: any): Observable<Object> {
    this.headerOptions = this._workflow.getHeaderOptions();
    return this._authHttp
      .post(AppConfig.API_ENDPOINT() + `/api/web/profile-lookup/update-language`, body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError('update_lang_failed', err)));
  }

  /**
   * update invite language with authorization token
   *
   * @param body - key, language, language_country_code.
   * update db with updated language(user selected language).
  */
  updateLanguage(body: any): Observable<Object> {
    this.headerOptions = this._workflow.getHeaderOptions();
    return this._authHttp
      .put(AppConfig.API_ENDPOINT() + `/api/v1/invite`, body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError('update_lang_failed', err)));
  }

  processLoadTranslation(workflowInstanceID: any) {
    // this._ts.loadTranslations(workflowInstanceID).subscribe(translations => {
    //   // this._ts.setTranslations(translations['translations']);
    //   this.localStorage.setItem('translationsFromDB', translations['translations']);
    // });
  }

  lookUpServicemobilenumber(body): Observable<any> {
    if(this.currentLanguage === undefined){
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    
    this.headerOptions = this._workflow.getHeaderOptions();
    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup-isdcodes/?code=${body}` + '&language=' + this.currentLanguage + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError((err => this._handleError('isdcodes_lookup_failed', err))));
  }

  sendEAuth(data: Object): Observable<any> {
    let workflowid = this.localStorage.getItem('workflow_instance_id');
    let action = '/api/v1/workflow/' + workflowid + '/eauth-step';
    let body = {
      'consent_id': data['consent_id'],
      'consent-field-list': data['consent-field-list'],
      'consent-base64': data['consent-base64'],
      'eauth-acceptance': data['eauth-acceptance'],
      'auth_type': data['auth_type'],
      'consent-acceptance': data['consent-acceptance'],
      'preknockout': data['preknockout'],
      'knockout': data['knockout'],
      'date': data['date'],
      'state': data['state']
    };

    return this._authHttp.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
      .pipe(
        map(res => res),
        catchError(err => this._handleError('eauth_put_failed', err)));
  }

  setEauthDisallowedToLocalStorage() {
    this.localStorage.setItem('is_eauth_disallowed', 'true');
  }

  setEauthAllowedToLocalStorage() {
    this.localStorage.setItem('is_eauth_disallowed', 'false');
  }

  publishData(data: any) {
    this.fieldErrorNotified.next(data);
  }

  /**
   * This functions returns months
   */
  getEngMonths() {
    let engMonths = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];

    return engMonths;
  }


  redirectToUnsupportedDevice(_service: AuthService, _route: Router) {
    _route.navigate(['/profile/unsupported-device']);

    return;
  }
  /**
   *
   * @param date - sleceted date
   * @param otherLangMonthNames - months in respective language
   * @param engmonthNames - months in english language
   */
  getDateFormatFromOtherlanguage(date, otherLangMonthNames, engmonthNames) {
    let otherLangIndex = otherLangMonthNames.indexOf(date.split(' ')[0]);
    let responseDate = engmonthNames[otherLangIndex] + ' ' + date.split(' ')[1];
    return responseDate;
  }

  /**
   *
   * @param eventName
   * @param key
   */
  trackEvent(eventName: any, key: any, args: any = {}) {
    args.invite_key = key;

    window.track_event(eventName, args);
  }

  /**
   *
   * @param remainingSection
   * @param percentage
   * @param remainingTime
   * @param completedMenu
   * @param eventBus
   */
  setStatusBar(remainingSection: any, percentage: any, remainingTime: any, completedMenu: any, eventBus: EventBusService) {
    if (completedMenu === undefined) {
      completedMenu = 0;
    }

    eventBus.statusbardAnnounced({
      remainingSection: remainingSection,
      percentage: percentage,
      remainingTime: remainingTime,
      completedMenu: completedMenu
    });
  }

  informOthersLanguageReceived(eventBusInstance: any, language: string) {
    eventBusInstance.languageInInviteReceivedAnnounced({
      isLanguageReceived: true,
      receivedLanguage: language
    });
  }

  informOthersLanguageReceivedAndSyncUp(eventBusInstance: any, language: string, tLang: string) {
    eventBusInstance.languageInInviteReceivedAnnounced({
      isLanguageReceived: true,
      receivedLanguage: language,
      translationsLanguage: tLang
    });
  }  

  getEncodeUrl(data: any) {
    return encodeURIComponent(data);
  }

  notifyManager(data: any) {
    this.reportingManagerNotified.next(data);
  }

  notifyCountry(data: any) {
    this.countryNotified.next(data);
  }

  notifyGenderSelection(data: any) {
    this.genderNotified.next(data);
  }

  getInterpolationData(text) {
    this.headerOptions = this._workflow.getHeaderOptions();
    const workflowid = this.localStorage.getItem('workflow_instance_id');
    const action = '/api/v1/workflow/' + workflowid + '/custom-form-variables-step';
    const self = this;
    return new Promise((resolve, reject) => {
      // Check if we have substitutable vars
      let vars: string[] = self.parseVariables(text);
      const vLen = vars.length;
      if (vLen == 0) {
        resolve(text);
        return;
      }

      let needLookup: boolean = false;
      for (let i = 0; i < vLen; i++) {
        if (self.customFormVariables.hasOwnProperty(vars[i]) && self.customFormVariables[vars[i]] && self.customFormVariables[vars[i]] != '') {
          continue;
        }
        needLookup = true;
        // console.log("Missing atleast ", vars[i]);
        break;
      }

      if (!needLookup) {
        const interpolatedString = this.interpolate(self.customFormVariables, text);
        resolve(interpolatedString);
        return;
      }

      self._authHttp.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), self.headerOptions)
        .pipe(catchError(err => this._handleError('custom_form_variables_failed', err))).subscribe(cfvObj => {
          self.customFormVariables = cfvObj;
          const interpolatedString = this.interpolate(self.customFormVariables, text);
          resolve(interpolatedString);
        });
    });
  }

  /**
   * parserVariables checks for variables ${}, which will be substituted.
   */
  parseVariables(text: string): string[] {
    let vars: string[] = [];
    var re = /\${([^{}]*)}/g;
    var m;
    do {
      m = re.exec(text);
      if (m) vars.push(m[1]);
    } while (m);
    return vars;
  }

  interpolate(cfvDataSet: any, value: string): string {
    const kvObject = Object.assign({}, cfvDataSet);
    if (value) {
      value = value.replace(/\${([^{}]*)}/g, function (match, ...keys) {
        var subst = kvObject[keys[0]] || '';
        let result = (typeof subst === 'string' || typeof subst === 'number') ? subst.toString() : match;
        return result;
      });
    }
    return value;
  }

  raisingEventTermsServiceHide(eventBus: EventBusService, value) {
    eventBus.announceTermsServicesHide({
      status: value
    });
  }

  checkInviteIsAssessment() {
    let inviteType = this.localStorage.getItem('inviteType');
    return (inviteType === 'ASSESSMENT') ? true : false;
  }

  checkInviteIsConsentType() {
    let inviteType = this.localStorage.getItem('inviteType');
    return (!!inviteType && inviteType.toUpperCase() === 'CONSENT') ? true : false;
  }

  checkInviteIsEIAF() {
    let inviteType = this.localStorage.getItem('inviteType');
    return (inviteType === 'EIAF') ? true : false;
  }

  checkInviteIsStandaloneWotc() {
    let inviteType = this.localStorage.getItem('inviteType');
    return (inviteType === 'STANDALONEWOTC') ? true : false;
  }

  checkInviteIsRightId() {
    let isEARightId = (this.localStorage.getItem('is_ea_rightId') == 'Y' ? true : false);
    return isEARightId;
  }

  checkInviteHasCaptureDLPrefill() {
    let isCaptureDLPrefill= (this.localStorage.getItem('is_capture_dl_prefill') == 'Y' ? true : false);
    return isCaptureDLPrefill;
  }

  checkInviteIsRightIdRouter() {
    let isEARightIdRouter = (this.localStorage.getItem('subType') == 'RightIDRouter' ? true : false);
    return isEARightIdRouter;
  }

  checkInviteIsKYP() {
    let isInviteKYP = (this.localStorage.getItem('subType') == AppConstants.INVITE_SUB_TYPE_KYP ? true : false);
    return isInviteKYP;
  }

  checkInviteSubTypeIsNYCFCA() {
    let subType = this.localStorage.getItem('subType'); 
    return  !!subType && subType === 'NYCFCA';
  }

  checkInviteSubTypeMonitoring() {
    let subType = this.localStorage.getItem('subType'); 
    return  !!subType && subType === 'MONITORING';
  }

   checkInviteIsCSPI() {
    let isInviteCspi = (this.localStorage.getItem('subType') == "CSPI" ? true : false);
    return isInviteCspi;
  }

  checkInviteIsMI() {
    let inviteType = this.localStorage.getItem('inviteType');
    return (inviteType && inviteType.toLowerCase() === 'mi') ? true : false;
  }

  checkInviteIsDQMI() {
    let inviteSubType = this.localStorage.getItem('subType');
    return (inviteSubType && (inviteSubType.toLowerCase() === 'dq' || inviteSubType.toLowerCase() === 'cspi')) ? true : false;
  }

  checkInviteIsAppMitigationMI() {
    let inviteSubType = this.localStorage.getItem('subType');
    return (inviteSubType && inviteSubType === 'APP_MITIGATION');
  }

  /**
   * Function to check invite is for DATA_CORRECTION or not
   *
   * @returns
   */
  checkInviteIsDataCorrection() {
    let inviteSubType = this.localStorage.getItem('subType');
    return (inviteSubType && (inviteSubType.toLowerCase() === 'dc')) ? true : false;
  }

  checkInviteIsNotification() {
    let inviteType = this.localStorage.getItem('inviteType');
    return (inviteType && inviteType === 'NOTIFICATION') ? true : false;
  }

  checkToShowTOS() {
    let isDisableTOS = false;
    if (this.localStorage.getItem('disable_tos') != undefined) {
      isDisableTOS = this.localStorage.getItem('disable_tos');
    }
    return isDisableTOS;
  }

  showBiometricConsent() {
    // Flag is set in backend for comp code - OTHER and subcode - FP/FNRA/NMLS
    let isBiometricInvite = (this.localStorage.getItem('show_biometric_consent') == 'Y' ? true : false);
    return isBiometricInvite;
  }

  processInviteType() {
    if (this.checkInviteIsMI() || this.checkInviteIsRightId() || this.checkInviteIsStandaloneWotc()) {
      return true;
    } else {
      return false;
    }
  }

  checkIsCriminalCategoryPreferences() {
    let isCriminalCategoryPreferenceSet = false;
    let criminalCategories = this.localStorage.getItem('criminalCategories');
    if (!!criminalCategories) {
      let splitCategories = criminalCategories.split(";").map(item => item.trim());
      if ((splitCategories.indexOf('CONVICTIONS') > -1) || (splitCategories.indexOf('PENDING_CHARGES') > -1) || (splitCategories.indexOf('CRIMINAL_REGISTRIES') > -1)) {
        isCriminalCategoryPreferenceSet = true;
      }
    }
    return isCriminalCategoryPreferenceSet;
  }

  checkIsAttestationPreference() {
    let isAttestationPref = (this.localStorage.getItem('attestationPreference') === 'YES' ? true : false);
    return isAttestationPref;
  }

  updateInviteStatus(body: any, invite_id: string): Observable<Object> {
    this.headerOptions = this._workflow.getHeaderOptions();

     return this._authHttp.
       put(AppConfig.API_ENDPOINT() + `/api/web/invite/` + invite_id + '/applicant/status', body, this.headerOptions).pipe(
        catchError(this._handleError)
      );
  }

  interactWithAnalytics(name: string, payload: any, isBehavioralMetrics: boolean, isOperationalMetrics: boolean, sendDeviceDetails?) {
    // isBehavioralMetrics (For Hotjar), isOperationalMetrics (For Log audit)
    if (isBehavioralMetrics) {
      this._hotjar.logHotjarEvent(name, payload);
    }
    
    if (isOperationalMetrics) {
      this.sendEventsToAudits(name, payload, sendDeviceDetails).subscribe(response => {
        console.log("Events sent to audits successfuly.....");
      }, error => {
        console.log("ERROR : Failed to send events to audits");
      });
    }
  }

  validateSINSNB(sin: any) {
    var check, even, tot;

    if (typeof sin === 'number') {
      sin = sin.toString();
    }

    if (sin.length === 9) {
      // convert to an array & pop off the check digit
      sin = sin.split('');
      check = +sin.pop();

      even = sin
        // take the digits at the even indices
        .filter(function (_, i) { return i % 2; })
        // multiply them by two
        .map(function (n) { return n * 2; })
        // and split them into individual digits
        .join('').split('');

      tot = sin
        // take the digits at the odd indices
        .filter(function (_, i) { return !(i % 2); })
        // concatenate them with the transformed numbers above
        .concat(even)
        // it's currently an array of strings; we want numbers
        .map(function (n) { return +n; })
        // and take the sum
        .reduce(function (acc, cur) { return acc + cur; });

      // compare the result against the check digit
      return check === (10 - (tot % 10)) % 10;
    } else return false;
  }

  showLanguageSelectorOnLogin() {

    const isInviteMI = this.checkInviteIsMI();
    const isStandaloneWotc = this.checkInviteIsStandaloneWotc();
    const firstAccess = this.localStorage.getItem('first_access');

    if (isInviteMI || isStandaloneWotc) {
      return false;
    }

    if (!firstAccess) {
      return true
    }

    return false;
  }

  setErrorCodeAndMsg(code, msg) {
    this.errorCode = code;
    this.errorMessage = msg;
  }

  /**
   * check google place search api is enabled or not
   *
   * @returns
   */
  isGooglePlaceApiEnabled(): Boolean {
    const placeSearchApi = this.localStorage.getItem('place_search_api');
    if (!!placeSearchApi && placeSearchApi === 'GOOGLE_API') {
      return true;
    }

    return false;
  }

  /**
   * check sms option is enabled to edit or not in settings page
   *
   * @returns
   */
  isSmsOptionEnabled(): boolean {
    const isEnabled = this.localStorage.getItem('SMS_OPTOUT_ENABLED');
    if (!!isEnabled && isEnabled === 'Y') {
      return true;
    }

    return false;
  }

  checkMinimumAllowedAge() {
    let minimumAllowedAge = 14;
    let steps = this._workflow.getSteps('missing-info-data-v1');

    for (var i = 0; i < steps.length; i++) {
      if (steps[i]['name'] == 'missing-info-step-v1') {
        if (steps[i]['ea-preferences'] !== undefined && steps[i]['ea-preferences'].length > 0) {
          let preferences = steps[i]['ea-preferences'];
          for (let pref of preferences) {
            if (pref['id'] === '1000') {
              minimumAllowedAge = pref['value'];
              this.localStorage.setItem('minimum_age', minimumAllowedAge);
              break;
            }
          }
        }
      }
    }
  }

  checkIsRightIDCPIC() {
    if (!!this.localStorage.getItem('package_components')) {
      let pkgComponents = this.localStorage.getItem('package_components');

      if (!!pkgComponents && pkgComponents.indexOf('INTLPRN') > -1)
        return true;
      else
        return false;
    }
  }

  /**
   * Function to get ea-preference value
   *
   * @param preferences
   * @param key
   * @returns
   */
  getEaPreferenceValueByKey(preferences: Array<{}>, key: string): any {
    let value = null;
    if (!preferences) {
      return null;
    }
    for (let prefer of preferences) {
      if (prefer['id'] === key) {
        if (prefer['value'] !== undefined && prefer['value'] !== null && prefer['value'] !== '') {
          value = prefer['value'];
        }
        break;
      }
    }
    return value;
  }

  setSelectedNotification(notification: any){
    this.selectedNotification = notification;
  }

  getSelectedNotification(){
    return this.selectedNotification;
  }

  /**
   * method to validate mobile os version supported by app
   */
   isSupportedMobileOs(): boolean {
    if ((this.deviceService.isMobile() || this.deviceService.isTablet()) && this.deviceService.getDeviceInfo().os.includes('Android') && this.getAndroidVersion() < environment.minAndroidVersion) {
      return false;
    } else if ((this.deviceService.isMobile() || this.deviceService.isTablet()) && (this.deviceService.getDeviceInfo().os.includes('iOS') || this.deviceService.getDeviceInfo().os.includes('Mac')) && this.getIosVersion() < environment.minIosVersion) {
      return false;
    }
    return true;
  }

  /**
   * method to validate browser version supported by app
   */
  isSupportedBrowserVersion(): boolean {
    const normalizedBrowserVersion = this.deviceService.getDeviceInfo().browser_version.replace('_', '.').split('.')[0];
    const browserVersion = +normalizedBrowserVersion;
    if (!browserVersion) {
      return false;
    }

    if (!this.deviceService.isDesktop()) {
      return true;
    }

    if (this.deviceService.getDeviceInfo().browser.includes('Chrome') && browserVersion < environment.minChromeVersion) {
      return false;
    } else if (this.deviceService.getDeviceInfo().browser.includes('Safari') && browserVersion < environment.minSafariVersion) {
      return false;
    } else if (this.deviceService.getDeviceInfo().browser.includes('Firefox') && browserVersion < environment.minFirefoxVersion) {
      return false;
    } else if (this.deviceService.getDeviceInfo().browser.includes('IE') && browserVersion < environment.minIExplorerVersion) {
      return false;
    }
    return true;
  }

  getAndroidVersion(): number {
    const match = this.deviceService.getDeviceInfo().userAgent.match(/android\s([0-9\.]*)/i);
    return match ? parseInt(match[1], 10) : 0;
  }

  getIosVersion(): number {
    const match = this.deviceService.getDeviceInfo().userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return parseInt(match[1], 10);
  }

  countryLookUpServiceByList(body): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let countryList = body['countryList'];
    let countryParam = body['countryParam'];

    if(this.currentLanguage === undefined){
      this.setDefaultLang(this.localStorage.getItem('language'));
    }

    const language = this.getLanguageCodeMapping(this.currentLanguage);

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/country/?list=${countryList}&code=${countryParam}` + '&language=' + language + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('country_lookup_withList_failed', err))
      );
  }

  getStagestate(stageName: string) {
    let wfId: any = this.localStorage.getItem("workflow_instance_id");
    this.headerOptions = this._workflow.getHeaderOptions();
    return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + wfId + '/stage-state/' + stageName, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('idv_profile_save_failed', err))
      );
  }

  sendEventsToAudits(name, body, sendDeviceDetails?): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let auditURL = AppConfig.API_ENDPOINT() + '/api/v1/audit/log-audit';
    
    this.preparePayloadForAudits(name, body, sendDeviceDetails);
    
    return this._authHttp.post(auditURL, this.payloadForAudits, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => {
          console.log('log audit error', err);
          return of();
        })
      );
  }

  convertToSpinalCase(str) {
    return str.toString().replace(/([A-Z])/g,' $1') /*Find all cap and add space at the start*/
              .replace(/[^A-Za-z0-9]/g,' ') /*Find all non alpha numeric and replace it with space*/
              .replace(/\s{1,}/g,"-") /*Convert all spaces to -*/
              .replace(/^\-|[\-]$/g,'') /*Slice - at the start and end*/
              .toLowerCase(); /*LowerCase it*/
  }

  preparePayloadForAudits(name, body, sendDeviceDetails?) {
    let data = {
      "workflow_instance_id": this.localStorage.getItem("workflow_instance_id"),
      "account_id":  this.localStorage.getItem('account_id'),
      "invite_id":this.localStorage.getItem("inviteId"),      
      "applicant_id":this.localStorage.getItem('applicant_id'),
      "order_id":  this.localStorage.getItem("wps_number"),
      "email": this.localStorage.getItem("applicant_email"),
      "invite_key": this.localStorage.getItem("key"),
      "profile_id" : this.localStorage.getItem('profile_id'),
      "visiter_id" : this.localStorage.getItem('visiter_id')
    };
    
    if (sendDeviceDetails) {
      data['device_details'] = this.sharedDeviceDetails;
    }
    
    this.payloadForAudits = {
      "name" : this.convertToSpinalCase(name),
      "category" : body['stage_name'],
      "at" : new Date(),
      "by" : this.localStorage.getItem('profile_id'),
      "action" : body['action_value'],
      "system" : AppConfig.SYSTEM,
      "data" : data
    }
  }
  
  // Call country lookup service
  countryLookUpServiceByIndexes(body, countryLookupAzureIndexUrl?): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptionsForAzure();
    let countryParam = this.getEncodeUrl(body['countryParam']);
    
    if (this.currentLanguage === undefined) {
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    
    let language = this.localStorage.getItem('language');
    language = this.getLanguageCodeMapping(language);
    let languageCode = 'en';
    let localeCode = 'US';

    if (language.indexOf('-') > -1) {
      let languageValue = language.split('-');
      languageCode = languageValue[0];
      localeCode = languageValue[1].toUpperCase();
    }
    
    let url: string = this.lookupBaseUrl + '/fa-countries-lookup-index/docs?api-version=2021-04-30-Preview';
    let filterParam: string = `$filter=language_cd eq '${languageCode}' and locale_country_cd eq '${localeCode}'`;
    const limitParam: string = '$top=50';
    const orderByParam: String = '$orderby=country_locale_name asc';
    let searchParam: string = "search=*";

    if (countryParam) {
      if (countryParam.length == 2) {
        filterParam = filterParam + ' and country_cd eq \'' + countryParam.toUpperCase() + '\'';
      } else {
        searchParam = "search=" + countryParam + "*";
      }
    }
    
    url = url + '&' + searchParam + '&' + filterParam + '&' + limitParam + '&' + orderByParam;
    
    return this._authHttp.get(url, this.headerOptions)
      .pipe(
      map(res => this._extractData(res)),
      map(res => this._doActionOnIndexesData(res)),
      catchError(err => this._handleError('country_lookup_failed', err))
      );
  }

  _doActionOnIndexesData(data) {
    let modifiedData = {};
    let countryData = data['value'];
    
    modifiedData['country_list'] = [];
    
    countryData = countryData.map(item => {
      return {
        'country_code': item['country_cd'],
        'country_name': item['country_locale_name']
      };
    });
    
    modifiedData['country_list'] = countryData;
    
    return modifiedData;
  }
  
  // Country iso code lookup service
  countryIsdCodeLookupServiceByIndexes(body, isdCodeLookupAzureUrl?) {
    this.headerOptions = this._workflow.getHeaderOptionsForAzure();

    if (this.currentLanguage === undefined) {
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    
    let url: string = this.lookupBaseUrl + '/fa-country-lookup-index/docs?api-version=2021-04-30-Preview';
    let searchParam: string = `search=${body}*`;
    let suggesterParam: string = 'suggesterName=fa-country-lookup-suggester-1';
    let selectParam: string = '$select=country_cd,country_name,collect_govt_id_flag,country_phone_prefix,region,iso_code_3';
    
    // let filterParam: string = `$filter=language_cd eq '${languageCode}' and locale_country_cd eq '${localeCode}'`;
    const limitParam: string = '$top=50';
    const orderByParam: String = '$orderby=country_name asc';

    // let url = this.lookupBaseUrl + `/fa-country-lookup-index/docs?api-version=2021-04-30-Preview&search=${body}*&suggesterName=fa-country-lookup-suggester-1&$select=country_cd,country_name,collect_govt_id_flag,country_phone_prefix,region,iso_code_3&$orderby=country_name asc`;
    url = url + '&' + searchParam + '&' + suggesterParam + '&' + selectParam + '&' + limitParam + '&' + orderByParam;

    return this._authHttp.get(url, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doActionOnIsdCodesIndexesData(res)),
        catchError(err => this._handleError('countrycodes_lookup_failed', err))
      );
  }
  
  _doActionOnIsdCodesIndexesData(data) {
    let modifiedData = {};
    let isdCodeData = data['value'];
    
    modifiedData['isd-code-list'] = [];
    
    isdCodeData = isdCodeData.map(item => {
      return {
        'code': '+' + item['country_phone_prefix'],
        'country': item['country_name'],
        'disp_value': item['country_name'] + ' +' + item['country_phone_prefix']
      };
    });
    
    modifiedData['isd-code-list'] = isdCodeData;
    
    return modifiedData;
  }
  
  stateLookUpServiceByIndexes(body, stateLookupAzureIndexUrl?): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptionsForAzure();
    let countryCode = body['countryCode'];
    let stateParam = this.getEncodeUrl(body['stateParam']);

    if (this.currentLanguage === undefined) {
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    
    const language = this.getLanguageCodeMapping(this.currentLanguage);

    let url = this.lookupBaseUrl + `/fa-state-locale-lookup-index/docs?api-version=2023-07-01-Preview&search=${stateParam}*&suggesterName=fa-state-locale-lookup-suggester&$filter=country_cd eq '${countryCode}' and language_cd eq 'en' and locale_country_cd eq 'US'&$top=50&$orderby=state_local_name asc`;
    
    return this._authHttp.get(url, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doActionOnStateIndexesData(res)),
        catchError(err => this._handleError('state_lookup_failed', err))
      );
  }
  
  private _doActionOnStateIndexesData(res: any): any {
    let modifiedData = {};
    let stateLookupData = res['value'];
    
    modifiedData['state-data-list'] = [];
    
    stateLookupData = stateLookupData.map(item => {
      return {
        'country_code': '+' + item['country_cd'],
        'state_code': item['state_cd'],
        'state_name': item['state_local_name']
      };
    });
    
    modifiedData['state-data-list'] = stateLookupData;
    
    return modifiedData;
  }
  
  cityLookUpServiceByIndexes(body, cityLookupAzureIndexUrl?): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptionsForAzure();
    let countryCode = body['countryCode'];
    let stateCode = body['stateCode'];
    let cityParam = body['cityParam'];

    if (this.currentLanguage === undefined) {
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    
    if (countryCode !== 'US') {
      // retun empty array instead of calling service for NON-US countries
      return of({ 'city-data-list': [] });
    }
    
    const language = this.getLanguageCodeMapping(this.currentLanguage);
    
    let url: string = this.lookupBaseUrl + '/fa-places-index/docs?api-version=2023-07-01-Preview';
    let searchParam: string = `search=${cityParam}*`;
    let filterParam: string = `$filter=state_cd eq '${stateCode}'`;
    const limitParam: string = '$top=50';
    const orderByParam: String = '$orderby=place_name asc';

    url = url + '&' + searchParam + '&' + filterParam + '&' + limitParam + '&' + orderByParam;
    
    return this._authHttp.get(url, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doActionOnCityIndexesData(res)),
        catchError(err => this._handleError('city_lookup_failed', err))
      );
  }
  
  private _doActionOnCityIndexesData(res: any): any {
    let modifiedData = {};
    let cityLookupData = res['value'];
    
    modifiedData['city-data-list'] = [];
    
    cityLookupData = cityLookupData.map(item => {
      return {
        'city': item['place_name'],
        'state_name': item['state_cd']
      };
    });
    
    modifiedData['city-data-list'] = cityLookupData;
    
    return modifiedData;
  }
  
  stateLookupSwitch(body): Observable<any> {
    let stateLookUpAzureIndexUrl = '';
    
    if (this.isAzureCognitiveSearch === 'Y') {
      return this.stateLookUpServiceByIndexes(body, stateLookUpAzureIndexUrl);
    } else {
      return this.stateLookUpService(body);
    }
  }
  
  countryCodeLookupSwitch(body): Observable<any> {
    let countryCodeLookUpAzureIndexUrl = '';
    
    if (this.isAzureCognitiveSearch === 'Y') {
      return this.countryLookUpServiceByIndexes(body, countryCodeLookUpAzureIndexUrl);
    } else {
      return this.countryLookUpService(body);
    }
  }
  
  isdCodesLookupSwitch(body): Observable<any> {
    let isdCodeLookUpAzureIndexUrl = '';
    
    if (this.isAzureCognitiveSearch === 'Y') {
      return this.countryIsdCodeLookupServiceByIndexes(body, isdCodeLookUpAzureIndexUrl);
    } else {
      return this.countryCodeLookUpService(body);
    }
  }
    
  checkFieldKypTranslateEnabled(formControlName) {
    let isLockedField = false;
    
    if (!!this.lockedFieldsList && this.lockedFieldsList.length > 0) {
      let index = this.lockedFieldsList.indexOf(formControlName);
      if (index !== -1) {
        isLockedField = true;
      }
    }
    
    return (this.isKypAccentPreferenceEnabled && isLockedField) ? true : false;
  }
  
  generateAccentsMapping() {
    let accentsMapping = SharedConfig.defaultAccentsMapping;

    for (let i=0; i < accentsMapping.length; i++) {
      let letters = accentsMapping[i].letters.split("");
      
      for (let j=0; j < letters.length; j++) {
        this.accentsMap[letters[j]] = accentsMapping[i].base;
      }
    }
  }
  
  translateAccentCharacters(data, moduleName) {
    let regExtest = new RegExp('(?![×÷])[\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0100-\u0147\u014A-\u017D\u1E9E]');
    var modifiedValue;
    let that = this;
    
    if (regExtest.test(data)) {
      modifiedValue = data.replace(/[^A-Za-z0-9\s]/g, function(a) {
        return that.accentsMap[a] || a; 
      });
      
      if (moduleName === 'PROFILE')
        this.storeProfileKypAccentsTranslatedData[data] = modifiedValue;
      else 
        this.storeAddressKypAccentsTranslatedData[data] = modifiedValue;
    }
    
    return !modifiedValue ? data : modifiedValue;
  }
  
  cityLookupSwitch(body): Observable<any> {
    let cityLookUpAzureIndexUrl = '';
    
    if (this.isAzureCognitiveSearch === 'Y') {
      return this.cityLookUpServiceByIndexes(body, cityLookUpAzureIndexUrl);
    } else {
      return this.cityLookUpService(body);
    }
  }
  
  zipLookUpServiceByIndexes(body, zipLookupAzureIndexUrl?): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptionsForAzure();
    let countryCode = body['countryCode'];
    let stateCode = body['stateCode'];
    let zipParam = body['zipParam'];
    let placeCode = body['placeCode'];

    if (this.currentLanguage === undefined) {
      this.setDefaultLang(this.localStorage.getItem('language'));
    }
    
    if (countryCode !== 'US') {
      // retun empty array instead of calling service for NON-US countries
      return of({ 'zipcode-data-list': [] });
    }
    
    const language = this.getLanguageCodeMapping(this.currentLanguage);
    
    let url: string = this.lookupBaseUrl + '/fa-zip-lookup-index/docs?api-version=2023-07-01-Preview';
    let searchParam: string = `search=${zipParam}*`;
    // let filterParam: string = `$filter=state_cd eq '${stateCode}'`;
    const limitParam: string = '$top=50';
    // const orderByParam: String = '$orderby=place_name asc';

    url = url + '&' + searchParam; //+ '&' + filterParam + '&' + limitParam + '&' + orderByParam;
    
    return this._authHttp.get(url, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doActionOnZipIndexesData(res)),
        catchError(err => this._handleError('city_lookup_failed', err))
      );
  }
  
  private _doActionOnZipIndexesData(res: any): any {
    let modifiedData = {};
    let zipLookupData = res['value'];
    
    modifiedData['zipcode-data-list'] = [];
    
    zipLookupData = zipLookupData.map(item => {
      return {
        'zip_code': item['zip_cd'],
        'city': '',   //item['']
        'state_code': ''
      };
    });
    
    modifiedData['zipcode-data-list'] = zipLookupData;
    
    return modifiedData;
  }
  
  zipLookupSwitch(body): Observable<any> {
    let zipLookUpAzureIndexUrl = '';
    
    if (this.isAzureCognitiveSearch === 'Y') {
      return this.zipLookUpServiceByIndexes(body, zipLookUpAzureIndexUrl);
    } else {
      return this.zipLookUpService(body);
    }
  }
  
  checkDistinctComponentAvailable(searchComponentName) {
    if (!!this.localStorage.getItem('package_components')) {
      let pkgComponents = this.localStorage.getItem('package_components');

      if (!!pkgComponents && pkgComponents.indexOf(searchComponentName) > -1)
        return true;
      else
        return false;
    }
  }
  
  // Update show_language_selection flag to false along with invite key
  updateShowLanguageSelectionFlag(): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    
    let body = {
      'show_language_selection': false,
      'key': this.localStorage.getItem('key')
    };
    
    return this._authHttp
      .put(AppConfig.API_ENDPOINT() + `/api/v1/invite`, body, this.headerOptions)
      .pipe(
        map(res => res),
        catchError(err => this._handleError('update show language selection failed', err))
      );
  }

  /**
   * Just to check the session is valid or not. 
   * PA will show the error prompt and re-direct to login page If session not valid,
   * 
   * @returns 
   */
  validateSession() {
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/session/isValid', this.headerOptions).pipe(
       catchError(this._handleError)
     );
  }

  /**
   * Returns if switch flow is enabled for the stage and step.
   * 
   * @param stageName
   * @param stepIndex 
   * @returns 
   */
  isSwitchFlow(stageName, stepIndex) {
    let isSwitchFlow = false;
    const stepConfig = this._workflow.getStepConfig(stageName, stepIndex);
    if (!!stepConfig['preferences'] && !!stepConfig['preferences']['is_switch_flow']) {
      isSwitchFlow = stepConfig['preferences']['is_switch_flow'];
    }
    return isSwitchFlow;
  }

  public logInfo(stageName: string, eventName: string, email: string, additionalInfo?: string): void {
    this.serverLogService.logToServer(stageName, eventName, email, additionalInfo).subscribe(res => {
    }, error => { console.log("Not able to log Info", error); });
  }

  public logFlow(stageName, eventName, additionalInfo?: string) {
    let email = this.localStorage.getItem("applicant_email");
    if (!email) {
      email = window.sessionStorage.getItem("Authy_subject");
    }
    this.logInfo(stageName, eventName, email, additionalInfo);
  }

  public logAuthyFlow(stageName, eventName, additionalInfo?: string) {
    let authysub = window.sessionStorage.getItem("Authy_subject");
    if (!!authysub) {
      this.logInfo(stageName, eventName, authysub, additionalInfo);
    }
  }
  
  public setDeviceDetails() {
    let isSupportedMobile = this.isSupportedMobileOs();
    let osData = this.deviceService.getDeviceInfo().os;
    
    if (!isSupportedMobile) {
      if (this.deviceService.getDeviceInfo().os.includes('Android')) {
        osData = osData + ' ' + this.getAndroidVersion();
      } else if (this.deviceService.getDeviceInfo().os.includes('iOS')) {
        osData = osData + ' ' + this.getIosVersion();
      }
    }
    
    this.sharedDeviceDetails = { 
      "device": this.deviceService.getDeviceInfo().device, 
      "os": osData, 
      "browser": this.deviceService.getDeviceInfo().browser, 
      "browser_version": this.deviceService.getDeviceInfo().browser_version,
      "is_mobile": this.deviceService.isMobile(),
      "is_tablet": this.deviceService.isTablet(),
      "is_desktop": this.deviceService.isDesktop(),
      "is_android": this.deviceService.getDeviceInfo().os.includes('Android'),
      "is_ios": this.deviceService.getDeviceInfo().os.includes('iOS'),
      "is_mac": this.deviceService.getDeviceInfo().os.includes('Mac'),
      "is_windows": this.deviceService.getDeviceInfo().os.includes('Windows'),
      "is_linux": this.deviceService.getDeviceInfo().os.includes('Linux')
    };
  }
  
  public getDeviceDetails() {
    return this.sharedDeviceDetails;
  }
}
