import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IdTokenClaims } from '@azure/msal-common'
import { LocalStorageService } from 'framework/localstorage.service';
import { Router } from '@angular/router';
import { AuthyService } from './authy.service';
import { jwtDecode } from 'jwt-decode';
import { SharedService } from 'app/shared/services/shared.service';

declare var $: any;

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

type LinkPolicyType = 'signin' | 'password-reset';


@Component({
  selector: 'app-authy',
  templateUrl: './authy.component.html',
  styleUrls: ['./authy.component.css']
})
export class AuthyComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();

  isIframe = false;
  loading = false;

  isLoggedIn = false;
  displayName = '';

  constructor(
    @Inject(MsalService) private authService: MsalService,
    @Inject(MsalBroadcastService) private msalBroadcastService: MsalBroadcastService,
    private authyService: AuthyService, private shareService: SharedService,
    private localStorage: LocalStorageService,private _router: Router
  ) {
  }

  ngOnInit(): void {
    $('#plsWaitOnIAgreeModal').modal('show');
    console.log('loading');

    // // initializes MSAL and configures redirect route.
     this.authService.handleRedirectObservable().subscribe();

    // get the invite
    const inviteKey = this.localStorage.getItem("invite_key");
    let that = this;
    this.authyService.getInviteIdToken(inviteKey).subscribe({
      next: (response) => {
        const { signUp } = environment.policies.authorities;

        // get the invite id if a user is logged in
        const claims = this.authService.instance.getActiveAccount()?.idTokenClaims as { extension_invite: string };
        const activeInvite = claims?.extension_invite;

        // jwt Decode - decode and send the client logo and url as state. CHeck invite component in authy-wip
        // decode the state when it redirects for forgot password and send the client url and logo
        const decoded: { clientColor?: string, clientLogoUrl?: string } = jwtDecode(response['idToken']);
        let state: string | undefined = undefined;
        
        if (decoded.clientColor || decoded.clientLogoUrl) {
          state = `client_info=${window.btoa(
            JSON.stringify({
              color: decoded.clientColor,
              logo: decoded.clientLogoUrl,
            })
          )}`;
        }
        that.shareService.logFlow("authy","get-authy-token");
        this.authService.loginRedirect({
          authority: signUp.authority,
          scopes: environment.policies.scopes,
          // if it's the invite of the current user, let B2C handle the session, the user will be prompted to login only if needed.
          // if it's a different user use the prompt=login to tell b2c to invoke the user journey.
          prompt: activeInvite === response['inviteKey'] ? 'none' : 'login', 
          extraQueryParameters: { id_token_hint: response['idToken'] },
          state
        });

         $('#plsWaitOnIAgreeModal').modal('hide');
      },
      error: () => {
        $('#plsWaitOnIAgreeModal').modal('hide');
        this._router.navigate(['./invalid']);
      },
    });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


}