import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkflowService } from 'framework/workflow.service';
import { LocalStorageService } from 'framework/localstorage.service';
import { LatencyChecker } from 'framework/latency-checker.service';
import { EventBusService } from 'framework/eventbus.service';
import { Modal, ModalService } from 'framework/modal/index';
import { PlaceHolderFloat } from 'framework/form/placeholder.directive';
import { LoginComponent } from '../../login/login.component';
import { AuthService } from './../../login/auth.service';
import { ErrorInfoComponent } from 'framework/form/errorInfo.directive';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from 'framework/i18n';
import { UtilService } from 'framework/utils/util.service';
import { SharedService } from '../../shared/services/shared.service';
import { AppConfig } from '../../app.config';
import { ProfileService } from '../profile.service';
import { PreAuthService } from '../../pre-auth/pre-auth.service';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-loader.service';
import { GoogleApiService } from 'app/shared/services/google-api.service';
import { environment } from 'environments/environment';

declare var window: any;
declare var $: any;
declare var document: any;

@Component({
  selector: 'set-password',
  templateUrl: './set-password.component.html',
  providers: [ProfileService, ModalService, PlaceHolderFloat, WorkflowService, LocalStorageService, LoginComponent, AuthService, ErrorInfoComponent, PreAuthService],
})
export class SetPasswordComponent implements OnInit {
  errorMessage: string = '';
  errorStatus: any;
  isError: boolean = false;
  email: string = '';
  MODAL_DEMO_ID: string;
  mService: ModalService;
  firstName: string = '';
  lastName: string = '';
  profileId: string;
  isCnfrmPwdError: boolean = false;
  isCnfrmPwdErrorInfo: boolean = false;
  cnfrmPwdErrorMessage: string = '';
  isPwdError: boolean = false;
  isPwdErrorInfo: boolean = false;
  pwdErrorMessage: string = '';
  isCnfrmPwdValid: boolean = false;
  isPwdValid: boolean = false;
  isGoneThroughTerms: boolean = false;
  startTime: any;
  endTime: any;
  timeDiff: any;
  userBandWidth: number;
  minThreshold: number;
  trackObj: Object;
  companyName: any;
  secretQuestions: any;
  secretQuestion: string;
  secretAnswer: string;
  isSecretQuestionValid: boolean = false;
  isSecretAnswerValid: boolean = false;
  isEnglishLangCode: boolean = false;
  selectedLanguageCode: string = 'en-gb';
  isInviteEiaf: boolean = false;
  isInviteConsentType = false;
  isInviteRightId: boolean = false;
  isInviteMI: boolean = false;
  isInviteDQMI: boolean = false;
  termsOfServicesData: any;
  isPasswordPolicyIssue: boolean = false;
  isStandaloneWotc: boolean = false;
  isInviteAssessment: boolean = false;
  isDisableTOS: boolean = false;
  static NAME_MASKING_PREFERNCE_VALUE = '1458';
  static NAME_STEP_INDEX = 0;
  isPasswordRulesValid: boolean = false;
  isInviteNotification: boolean = false;
  isAuthyLoggedIn: boolean = false;

  constructor(private _router: Router,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService,
    private latencyChecker: LatencyChecker,
    private _preAuthService: PreAuthService,
    private _profileService: ProfileService,
    private _eventBus: EventBusService,
    private _modService: ModalService,
    private _placeholder: PlaceHolderFloat,
    private _loginComponent: LoginComponent,
    private _authService: AuthService,
    private _errorInfo: ErrorInfoComponent,
    private _translate: TranslateService,
    private _util: UtilService,
    @Inject(DOCUMENT) private document: any,
    private _shareService: SharedService,
    private _sanitizer: DomSanitizer,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private googleApiService: GoogleApiService)
  {
    this.MODAL_DEMO_ID = 'someUniqueName';
    this.mService = _modService;
    this.startTime = new Date().getTime();
    this.minThreshold = AppConfig.getMinThreshold();
    this.selectedLanguageCode = this._translate.currentLang;
  }

  ngOnInit() {
    this.isAuthyLoggedIn = this.localStorage.getItem("isAuthyLoggedIn");
    
    // Inform header component to enable/disable language Icon
    if (!this.isAuthyLoggedIn) {
      this._eventBus.languageListInInviteReceivedAnnounced({
        availableLangList: this.localStorage.getItem('availableLanguageList')
      });
    } else {
      this._eventBus.languageListInInviteReceivedAnnounced({
        availableLangList: this.localStorage.getItem('availableLanguageList'),
        hideLanguageSelectorInLoginPage: true
      });
    }

    this.isInviteConsentType = this._shareService.checkInviteIsConsentType();
    this.isInviteEiaf = this._shareService.checkInviteIsEIAF();
    this.isInviteRightId = this._shareService.checkInviteIsRightId();
    this.isStandaloneWotc = this._shareService.checkInviteIsStandaloneWotc();
    this.isInviteMI = this._shareService.checkInviteIsMI();
    this.isInviteAssessment = this._shareService.checkInviteIsAssessment();
    this._placeholder.placeholderFloatFunc();
    this.email = this.localStorage.getItem('applicant_email');
    this.isInviteDQMI = this._shareService.checkInviteIsDQMI();
    this.isInviteNotification = this._shareService.checkInviteIsNotification();

    this.setTermsOfServiceData();

    if (this.email === undefined) {
      this.localStorage.setItem('Status_500', 'YES');
      this._router.navigate(['/invalid']);
    }
    this.firstName = this.localStorage.getItem('first_name');
    this.lastName = this.localStorage.getItem('last_name');
    this.profileId = this.localStorage.getItem('profile_id');
    this._errorInfo.errorInfo();
    if (this.localStorage.getItem('Recruiter_Name') !== null && this.localStorage.getItem('Recruiter_Name') !== undefined) {
      // this.companyName = " " + this.localStorage.getItem('Recruiter_Name');
      this.companyName = " " + this.localStorage.getItem('Recruiter_Name');
    }
    this.secretQuestions = ProfileService.getSecurityQuestions();

    // Get language code and identify whether its is English/UK English or other language
    this.identifyLanguage();
    if (!!this.isAuthyLoggedIn) {
      this.signUpSuccess();
    }
  }

  ngAfterViewInit() {
    this.isInviteConsentType = this._shareService.checkInviteIsConsentType();
    this.isInviteEiaf = this._shareService.checkInviteIsEIAF();
    this.isStandaloneWotc = this._shareService.checkInviteIsStandaloneWotc();
  }

  /**
  * To identify the network bandwidth, and
  * notify the users if the available bandwidth is lesser than minimum threshold
  */
  checkLatency() {
    this.latencyChecker.getDownloadBandWidth().subscribe(dldBandwidth => {
      this.userBandWidth = dldBandwidth['speedMbps'];
      if (dldBandwidth['speedMbps'] < this.minThreshold) {
        $('#threshHoldAlertModal').modal('show');
        setTimeout(function () { $('#threshHoldAlertModal').modal('hide'); }, AppConfig.APP_CONSTANTS.threshHoldMsgTimeOut);
      };
    })
    window.sessionStorage.setItem('isDldBandWidthChecked', 'true');
  }

  closeTModal() {
    $('#threshHoldAlertModal').modal('hide');
  }

  modalService(val: any) {
    this.mService.open(val);
  }

  validatePassword(value: Object) {
    let that = this;
    this.isPasswordPolicyIssue = false;
    this.isPwdErrorInfo = false;
    this._profileService.validatePassword(value).subscribe(response => {

      window.track_event('validate password', {
        stage_name: 'set-password', action_value: 'sign up'
      }, true, true);

      let isEARightId = that._shareService.checkInviteIsRightId();
      this.isDisableTOS = this._shareService.checkToShowTOS();
      let isKyp = this._shareService.checkInviteIsKYP();
      if (!isKyp && (isEARightId || this.isInviteMI || this.isDisableTOS)) {
        that.setPassword(value);
      } else {
        $('#myModal').modal('show');
      }
    }, error => {
      this.errorMessage = error.message;
      this.isError = true;
      console.log('error while validate password ', this.errorMessage);
    });

  }

  setPassword(value: Object): void {
    $('#myModal').modal('hide');
    $('#plsWaitOnIAgreeModal').modal('show');
    this.isGoneThroughTerms = true;

    let that = this;

    if (this.isGoneThroughTerms) {
      this.endTime = new Date().getTime();
      this.timeDiff = (this.endTime - this.startTime) / 1000;
      this.timeDiff = Math.floor(this.timeDiff);

      this._profileService.setPassword(value).subscribe(response => {
        if (response) {
          that.signUpSuccess();
        }
      },
        error => {
          this.trackObj = {
            stage_name: 'set-password',
            time_spent: this.timeDiff,
            action_value: 'sign up'
          };

          window.track_event('sign up failed', this.trackObj, true, true, true);

          this.errorMessage = error.message;
          this.isError = true;
          if (this.errorMessage === '409') {
            this.localStorage.setItem('Status_409', 'YES');
            this.redirectSignin();
          }
          else if (this.errorMessage === '500') {

            this.localStorage.setItem('Status_500', 'YES');
            this._router.navigate(['/invalid']);

          } else if (this.errorMessage === '502') {

            this.localStorage.setItem('Status_502', 'YES');
            this._router.navigate(['/invalid']);
          } else {

            console.log('ELSE BLOCK OF ERROR');
          }
          $('#plsWaitOnIAgreeModal').modal('hide');
        });
    }
  }

  signUpSuccess() {
    // load required js/css files
    this.dynamicScriptLoader.loadScripts();

    let trackObj = {
      stage_name: 'set-password',
      time_spent: this.timeDiff,
      action_value: 'setting password'
    }

    window.track_event("sign up completed", trackObj, true, true);

    this.trackObj = {
      stage_name: 'set-password',
      action_value: 'setting password'
    };

    window.track_event('sign up attempted', this.trackObj, true, true, true);

    // Post message
    window.safeNotifyHost('workflow_started', null);

    let invitekey = this.localStorage.getItem('key');
    $('#plsWaitOnIAgreeModal').modal('show');
    this._profileService.getProfile(this.profileId).subscribe(profileResponse => {
      if (invitekey === null) {
        this._profileService.inviteByProfile(this.profileId).subscribe(inviteResponse => {
          this.processResponse(inviteResponse);
          // this.loadTranslation(inviteResponse);
        },
          error => {
            $('#plsWaitOnIAgreeModal').modal('hide');
            this.errorMessage = <any>error;
            this.isError = true;
          });
      } else {
        this._preAuthService
          .validateToken(invitekey)
          .subscribe(inviteResponse => {
            this.processResponse(inviteResponse);
            // this.loadTranslation(inviteResponse);

            this.trackObj = {
              stage_name: 'set-password',
              time_spent: this.timeDiff,
              action_value: 'sign up'
            };

            window.track_event('sign up completed', this.trackObj, true, true, true);
          },
            async error => {
              /**
               * Error object structure returned from validateToken() method
               * {
               *     'language': language,
               *     'languageCountryCode': languageCountryCode,
               *     'errorMessage': errorMessage
               *  }
               */
              this.errorMessage = <any>error;
              this.isError = true;
              $('#plsWaitOnIAgreeModal').modal('hide');
              if (this.errorMessage['errorMessage'] === 'EXPIRED') {
                /**
                  * Set the language for the expiry page based on
                  * invite language
                  * #TODO - Hard Coded value to be replaced based on API
                  * response
                  */
                await this._shareService.setLanguage({
                  'language': this.errorMessage['language'],
                  'language_country_code': this.errorMessage['languageCountryCode']
                }, this._eventBus);

                this._router.navigate(['/expired']);
              } else if (this.errorMessage['errorMessage'] === 'INVALID') {
                this._router.navigate(['/invalid']);
              }
            });
      }
    }, error => {
      this.errorMessage = <any>error;
      this.isError = true;
    });
  }

  processResponse(inviteResponse: any): void {
    $('#plsWaitOnIAgreeModal').modal('hide');
    this._shareService.setLanguage(inviteResponse, this._eventBus);

    this.localStorage.setItem("selected_invite_notification", inviteResponse['notification']);

    const placeSearchAPi = inviteResponse['account']['place_search_api'];
    this.localStorage.setItem('place_search_api', placeSearchAPi);
    if (this._shareService.isGooglePlaceApiEnabled()) {
      // add google api js into script tag for the specific language
      this.googleApiService.load(inviteResponse['language']);
    }

    if (!!inviteResponse['applicant']) {
      this.setApplicantDataInLocalStorage(inviteResponse['applicant']);
    }

    if (!!inviteResponse['account']) {
      this.localStorage.setItem('SMS_OPTOUT_ENABLED', inviteResponse['account']['SMS_OPTOUT_ENABLED'])
    }

    this.document.getElementById('brand').innerHTML = inviteResponse['custom_styles'];
    
    if (!!inviteResponse['workflow_instance']['id']) {
      console.log('wid ', inviteResponse['workflow_instance']['id']);
      this.localStorage.setItem('workflow_instance_id', inviteResponse['workflow_instance']['id']);
    }
    console.log('test wid ', this.localStorage.getItem('workflow_instance_id'))
    let counsentCountryCode = inviteResponse['current_address_country'];
    this.localStorage.setItem('is_country_based_consent', inviteResponse['is_country_based_consent']);
    this._shareService.raisingEventTermsServiceHide(this._eventBus, false);

    // Check invite is RightId or Eiaf or Mi
    const isInviteTypeEnabled = this._shareService.processInviteType();
    const isEARightId = this._shareService.checkInviteIsRightId();
    const isEARightIdRouter = this._shareService.checkInviteIsRightIdRouter();
    const ridStatus = this.localStorage.getItem('rid_status');
    const showLanguageSelection = this.localStorage.getItem('show_language_selection');
    let availableLangList =  this.localStorage.getItem('availableLanguageList');
    
    let skipLanguageSelection = !this.isAuthyLoggedIn || (!availableLangList || availableLangList.length === 0) || (isInviteTypeEnabled && !isEARightId) || !showLanguageSelection ? true : false;
    let inviteTypeNotification = this._shareService.checkInviteIsNotification();
    
    if (inviteTypeNotification) {
      this.localStorage.setItem('notification_mode', 'true');
      if (!skipLanguageSelection) {
        this._next('/language-selection');
      } else {
        this._next('/dashboard');
      }
      return;
    }

    let applicantInstructions = inviteResponse['applicant']['instructions'];
    if (!!applicantInstructions && applicantInstructions.length > 0 &&
      !(inviteResponse['applicant']['status'] === 'SUBMITTED' ||
        inviteResponse['applicant']['status'] === 'INACTIVE' ||
        inviteResponse['applicant']['status'] === 'CONSENT_DECLINE' ||
        inviteResponse['applicant']['status'] === 'INVITE_FAILURE' ||
        inviteResponse['applicant']['status'] === 'ASYNC_SUBMITTED' ||
        inviteResponse['applicant']['status'] === 'PENDING_REVIEW')) {
      this.localStorage.setItem("applicantInstructions", applicantInstructions);
      this._next('/instructions/customerInstructions');
      $('#plsWaitOnIAgreeModal').modal('hide');
    } else if (!skipLanguageSelection) {
      $('#plsWaitOnIAgreeModal').modal('hide');
      this._next('/language-selection');
    } else if (inviteResponse['is_country_based_consent'] && counsentCountryCode == undefined
      && (!isInviteTypeEnabled || (isEARightId && isEARightIdRouter && ridStatus === 'OPTED_OUT_DEVICE' || ridStatus === 'DERIVED'))) {
      //Need to replace with Backend flag from invite Response
      $('#plsWaitOnIAgreeModal').modal('hide');
      this._next('/country-consent');
    } else {
      this._workflow.getWorkFlow(inviteResponse['workflow_instance']['id']).subscribe(response => {
        this.trackObj = {
          stage_name: 'Rightid',
          action_value: 'Rightid set password'
        };

        window.track_event('signup successful', this.trackObj, true, true, true);

        if (!this.isInviteMI) {
          this._workflow.getDynamicCDConfig(null);
        }
        this._workflow.storeDocBaseUrl();
        $('#plsWaitOnIAgreeModal').modal('hide');
        this.raisingEvent();
        this._next(response['URL']);
        // this._next('/home/dynamic-information-capture');
      }, error => {
        $('#plsWaitOnIAgreeModal').modal('hide');
      });
    }

    this.getCountryNameByCountryCode(counsentCountryCode);

    this.sendEventForApplicanrRegd();
  }

  getCountryNameByCountryCode(countryCode) {
    if (countryCode != undefined) {
      this._shareService.countryLookUpServiceByCountryCode(countryCode).subscribe(response => {
        let countryData = response;
        let countryList = countryData['country_list'];
        if (countryList != undefined && countryList.length == 1) {
          this.localStorage.setItem('consentCountry', JSON.stringify(countryList[0]))
        }
      });
    }
  }

  redirectSignin(): void {
    this._next('./login');
  }

  redirectDiffEmail(): void {
    this._next('./diff-email');
  }

  private _next(url): void {
    this._router.navigate([url]);
  }

  validatePwd(pwdValue: string, cnfrmPwdValue: string) {
    this.document.getElementById('meter').style.display = "none";
    if (pwdValue == "") {
      this.isPwdError = true;
      this.isPwdErrorInfo = true;
      this.pwdErrorMessage = 'PASSWORD_ERROR_MSG_1';
      this.isPwdValid = false;
    }
    else if (pwdValue.length < 8) {
      this.isPwdError = true;
      this.isPwdErrorInfo = true;
      this.pwdErrorMessage = 'PASSWORD_ERROR_MSG_2';
      this.isPwdValid = false;
    }
    else if (pwdValue.length > 5 && cnfrmPwdValue.length > 5 && pwdValue === cnfrmPwdValue) {
      this.isPwdError = false;
      this.isPwdErrorInfo = false;
      this.pwdErrorMessage = '';
      this.isPwdValid = true;
      this.isCnfrmPwdValid = true;
    }
    else {
      this.isPwdError = false;
      this.isPwdErrorInfo = false;
      this.pwdErrorMessage = '';
    }
  }

  closePwdError() {
    this.isPwdError = false;
  }

  validateConfirmPwd(pwdValue: string, confrmPwdvalue: string) {
    if (pwdValue.length > 5 && confrmPwdvalue == "") {
      this.isCnfrmPwdError = true;
      this.isCnfrmPwdErrorInfo = true;
      this.cnfrmPwdErrorMessage = 'PASSWORD_ERROR_MSG_3';
      this.isCnfrmPwdValid = false;
    } else if (pwdValue != "" && confrmPwdvalue != "") {
      if (pwdValue.length == confrmPwdvalue.length && pwdValue != confrmPwdvalue) {
        this.isCnfrmPwdError = true;
        this.isCnfrmPwdErrorInfo = true;
        this.cnfrmPwdErrorMessage = 'PASSWORD_ERROR_MSG_4';
        this.isCnfrmPwdValid = false;
      }
      else if (pwdValue.length != confrmPwdvalue.length) {
        this.isCnfrmPwdError = true;
        this.isCnfrmPwdErrorInfo = true;
        this.cnfrmPwdErrorMessage = 'PASSWORD_ERROR_MSG_4';
        this.isCnfrmPwdValid = false;
      }
      else {
        this.isCnfrmPwdError = false;
        this.isCnfrmPwdErrorInfo = false;
        this.cnfrmPwdErrorMessage = '';
        this.isCnfrmPwdValid = true;
      }
    } else {
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
      this.cnfrmPwdErrorMessage = '';
      this.isCnfrmPwdValid = true;
    }
  }

  closeCnfrmPwdError() {
    this.isCnfrmPwdError = false;
  }

  removeReadOnlyPwd() {
    $('#inputSetPwd').removeAttr('readonly');
  }

  removeReadOnlyCnfrmPwd() {
    $('#inputsCnfrmPwd').removeAttr('readonly');
  }

  enableNext(pwdValue: string, cnfrmPwdValue: string, secretQuestion: string, secretAnswer: string) {
    if (pwdValue.length > 5 && cnfrmPwdValue.length > 5 && pwdValue === cnfrmPwdValue) {
      this.isCnfrmPwdValid = true;
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
      this.isPwdValid = true;
    } else {
      this.isCnfrmPwdValid = false;
      this.isPwdValid = false;
    }
    this.validateSecretQuestion(secretQuestion);
    this.validateSecretAnswer(secretAnswer);
  }

  validateSecretQuestion(secretQuestion: string) {
    if (secretQuestion && secretQuestion.length > 0) {
      this.isSecretQuestionValid = true;
    } else {
      this.isSecretQuestionValid = false;
    }
  }

  validateSecretAnswer(secretAnswer: string) {
    if (secretAnswer && secretAnswer.length > 0) {
      this.isSecretAnswerValid = true;
    } else {
      this.isSecretAnswerValid = false;
    }
  }

  hideError(password: string, confirmPass: string) {
    if (confirmPass.length > 0) {
      if (password == confirmPass) {
        this.isCnfrmPwdValid = true;
      } else {
        this.isCnfrmPwdValid = false;
      }
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
    }
    else {
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
      this.isCnfrmPwdValid = false;
    }
  }

  hidePwdError() {
    this.document.getElementById('meter').style.display = "block";
    this.isPwdError = false;
    this.isPwdErrorInfo = false;
  }

  disagreeTermsAndConditions() {
    $('#myModal').modal('hide');
    this._next('./profile/disagreeterms');
  }

  private raisingEvent() {
    let nameConfig = this._workflow.getStepConfig('main-data-v1', SetPasswordComponent.NAME_STEP_INDEX);
    let value = this._shareService.getEaPreferenceValueByKey(nameConfig['ea-preferences'],SetPasswordComponent.NAME_MASKING_PREFERNCE_VALUE);
    let lastNameMaskingValue = '';
    if(value){
      lastNameMaskingValue = '******';
    } else {
      lastNameMaskingValue = this.lastName;
    }
    this._eventBus.announceMission({
      usename: this.firstName + ' ' + lastNameMaskingValue,
      isUserInfoShown: true
    });
  }

  private identifyLanguage() {
    let langCode = this.localStorage.getItem('language');
    if (langCode === 'en' || langCode === 'en-gb') {
      this.isEnglishLangCode = true;
    }
  }

  private sendEventForApplicanrRegd() {
    if(this.isInviteConsentType) {
      // No registration event needed to report
      return ;
    }

    if (this.isInviteEiaf) {
      this._shareService.putOrderEiaf()
        .subscribe(response => {

        },
          error => {
          });
    } else {
      this._shareService.putOrder("Applicant_Registered", 'Started')
        .subscribe(response => {
        },
        error => {
        });
    }
  }

  private setTermsOfServiceData() {
    if( this.isStandaloneWotc && this.localStorage.getItem('isIntlPreferenceOn')) {
      this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant('TERMS_AND_CONDITIONS_WOTC_CONTENT'));
    } else if(this.isInviteAssessment) {
      this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant('TERMS_AND_CONDITIONS_ASSESSMENT_CONTENT'));
    } else {
      const termsOfServiceKey = this.localStorage.getItem('isIntlPreferenceOn') ? 'TERMS_AND_CONDITIONS_CONTENT_INTL' : 'TERMS_AND_CONDITIONS_CONTENT';
      this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant(termsOfServiceKey));
    }
  }

  setApplicantDataInLocalStorage(applicateData) {
    this.localStorage.setItem('first_name', applicateData['first_name']);
    this.localStorage.setItem('applicant_email', applicateData['email']);
    this.localStorage.setItem('last_name', applicateData['last_name']);
    this.localStorage.setItem('mobile', applicateData['mobile']);
  }

  isPasswordValid(isValid: boolean){
    this.isPasswordRulesValid = isValid;
  }
}
