import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Inject, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { Store } from '@ngrx/store';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageService } from 'framework/localstorage.service';
import { LatencyChecker } from 'framework/latency-checker.service';
import { EventBusService } from 'framework/eventbus.service';
import { WorkflowService } from 'framework/workflow.service';

// Import Modules
import { StoreModule } from '@ngrx/store';
import { DatePipe } from '@angular/common';

import { AuthService } from './login/auth.service';
import { AuthGuard } from './login/auth-guard.services';
// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './header';
import { FooterComponent } from './footer';

// Import your library
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HooksService } from './shared/services/hooks.service';

//Dynamic Title service
import { Title } from '@angular/platform-browser';

// Import Application wide shared services
import { ExceptionService } from 'framework/exception/exception.service';
import { GlobalErrorHandler, LOGGING_ERROR_HANDLER_PROVIDERS } from 'framework/exception/exception.handler.service';
import { LanguagePickerModule } from 'framework/languagePicker';

import { environment } from '../environments/environment';
import { QuestionnaireFormComponent } from './consent/step/questions/questionnaire-form.component';
import { SharedService } from './../truid/identity/src/lib/shared/Services/shared.service';
import { ResetPasswordComponent, SetPasswordComponent } from './profile';
import { ExpiredInviteComponent, InvalidInvitationComponent } from './pre-auth';

import { PreAuthModule } from './pre-auth/pre-auth.module';
import { CardModule } from 'ngx-card';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ProfileModule } from './profile/profile.module';
import { ViewProfileModule } from './view-profile/view-profile.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppInit } from './app.init';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader.service';
import { GoogleApiService } from './shared/services/google-api.service';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { PasswordMeterComponent } from './profile/password-meter/password-meter.component';
import { OidcLoginModule } from './oidc-login/oidc-login.module';
import { OidcRegistrationComponent } from './profile/oidc-registration/oidc-registration.component';
import { BaseSetPasswordComponent } from './base-set-password/base-set-password.component';
import { BaseLoginComponent } from './base-login/base-login.component';
import { MfaSelectionModule } from './mfa-selection/mfa-selection.module';
import { MfaVerificationModule } from './mfa-verification/mfa-verification.module';
import { LoaderOnlyComponent } from './loader-only/loader-only.component';
import { MfaCallbackComponent } from './mfa-callback/mfa-callback.component';
import { ProfileService } from './profile/profile.service';
import { OidcResetPasswordComponent } from './profile/oidc-reset-password/oidc-reset-password.component';
import { MfaLogoutComponent } from './mfa-logout/mfa-logout.component';
import { InstantIdentityComponent } from './instant-identity/instant-identity.component';
import { MSALGuardConfigFactory, MSALInstanceFactory } from './authy/authy.module';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    QuestionnaireFormComponent,
    SetPasswordComponent,
    BaseSetPasswordComponent,
    OidcRegistrationComponent,
    OidcResetPasswordComponent,
    ResetPasswordComponent,
    ExpiredInviteComponent,
    InvalidInvitationComponent,
    PasswordMeterComponent,
    LoaderOnlyComponent,
    BaseSetPasswordComponent,
    BaseLoginComponent,
    MfaCallbackComponent,
    MfaLogoutComponent,
    InstantIdentityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    OidcLoginModule,
    MfaSelectionModule,
    MfaVerificationModule,
    HttpClientModule,
    PreAuthModule,
    SharedModule,
    FormsModule,
    StoreModule.forRoot({}),
    SlickCarouselModule,
    LanguagePickerModule,
    CardModule,
    ProfileModule,
    ViewProfileModule,
    NgSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    DynamicScriptLoaderService,
    GoogleApiService,
    HooksService,
    ExceptionService,
    LOGGING_ERROR_HANDLER_PROVIDERS,
    AuthService,
    LocalStorageService,
    LatencyChecker,
    EventBusService,
    WorkflowService,
    ProfileService,
    AuthGuard,
    Store,
    Title,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: APP_INITIALIZER, multi: true, deps: [AppInit], useFactory: (appInit: AppInit) => () => appInit.initialize() },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [PasswordMeterComponent]
})
export class AppModule {
  constructor(@Inject(SharedService) sharedService: SharedService,
              @Inject(ExceptionService) exception: ExceptionService) {
    // API is exposed through this apm instance
    const env = environment;
  }
}
